import React from 'react';
import styled from 'styled-components';
const EmptyStateContainer= styled.div`
 padding: 50px 80px 30px;
  border-radius: 20px;
  border: 1px dashed ${({dark,theme})=>dark === "dark" ? theme.black : theme.primary};
  text-align: center;
  color: ${({dark,theme})=>dark === "dark" ? theme.black : theme.primary};
  & h2{
    font-weight: bold;
    font-size: 1.563rem;
    line-height: 1.3;
    letter-spacing: 0.05em;
    color: ${({dark,theme})=>dark === "dark" ? theme.black : theme.primary};
    margin-bottom: 5px;
  }
  & p{
    font-size: 1rem;
    line-height: 1.5;
    color: ${({dark,theme})=>dark === "dark" ? theme.black : theme.primary};
    letter-spacing: 0.01em;
  }
`
export const ErrorMessageBox = ({ dark,title,children }) => (
    
    <div className="row gx-0 justify-content-center align-items-center w-100">
        <div className="col-lg-12 p-3">
            <EmptyStateContainer dark={dark}>
                <h2>{title}</h2>
                {children}
            </EmptyStateContainer>
        </div>
    </div>
)