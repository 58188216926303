import React, { useState, useEffect } from "react";
import { Button } from "elements/Button";
import { Input } from "elements/Input";
import { Link, useLocation } from "react-router-dom";
import Document from "layout/Document";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserService } from "utility/services";
import { ErrorMessages } from "utility/constants/common";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import { Heading, SubHeading, Text } from "elements/Common";
import { MessagePopup } from "components/MessagePopup";

const Form = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  @media (max-width: 668px) {
    padding: 0rem;
  }
`;
const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
`;
const Links=styled(Link)`
font-size: 1.563rem !important;
`;
const Container = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Right = styled.div`
  width: 100%;
  padding: 4rem 9rem 1rem 4rem;
  min-height: 93vh;
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    min-height: 65vh;
    height: auto;
  }
  & h2 {
    text-align: left;
    font-size: 2rem;
    color: ${({ theme }) => theme.heading};
    text-align: left;
    @media (max-width: 1023px) {
      font-size: 1.8rem;
    }
    @media (max-width: 668px) {
      font-size: 1.5rem;
    }
  }
 
  @media (max-width: 668px) {
    margin-bottom: 2rem;
  }
`;
const InputWrapper = styled.div`
  padding: 0.5rem 0rem;
`;
const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  min-height: 93vh;
  height: 100%;
  padding: 4rem 6rem 1rem 6rem;
  background-color: ${({ theme }) => theme.secondary};

  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    padding-top: 8rem;
    min-height: 65vh;
    height: auto;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ConfirmationText = styled.p`
  color: ${({ theme }) => theme.heading};
  margin-top: 1rem !important;
`;

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required.")
    .min(4, "Password should between 4 and 100.")
    .max(100, "Password should be between 4 and 100."),
  confirmPassword: yup
    .string()
    .required("Confirm password is required.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

const ResetPassword = () => {
  const [success, setSuccess] = useState(false);
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let { search } = useLocation();

  const {
    register: reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  useEffect(() => {
    const key = new URLSearchParams(search).get("token");
    if (key) {
      setKey(key);
    } else {
      setError("Invalid Link, Please contact administrator");
    }
  }, [search]);

  const onSubmit = async (data) => {
    try {
      setSuccess(false);
      setLoading(true);
      await UserService.resetPassword({
        key,
        newPassword: data.password,
      });
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setError(ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Document title="reset password">
      <Container>
        <Row className="gx-0 w-100">
          <Col lg={6} md={6} sm={12} xs={12}>
            <Left>
              <Content>
                <SubHeading>Password Reset</SubHeading>
                <Heading>
                  Enter new password to access your Pitch IN for Ukraine Account
                </Heading>
              </Content>
            </Left>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Form>
              <Wrapper>
                <Right>
                  <Text>
                    Create an account by making a donation{" "}
                    <span>
                      <Link to="/"> here</Link>
                    </span>
                  </Text>
                  {!success ? (
                    <form
                      name="formReset"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <InputWrapper>
                        <Input
                          {...reset("password")}
                          errors={errors?.password}
                          placeholder="Password"
                          name="password"
                          type="password"
                          showPassword={showPassword}
                          setShowPassword={setShowPassword}
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <Input
                          {...reset("confirmPassword")}
                          errors={errors?.confirmPassword}
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          type="password"
                          showPassword={showConfirmPassword}
                          setShowPassword={setShowConfirmPassword}
                        />
                      </InputWrapper>

                      <Links to="/"> Back to login</Links>

                      <ButtonWrapper>
                        <Button type="submit" width="50%">
                          {loading ? "Loading..." : "Submit"}
                        </Button>
                      </ButtonWrapper>
                    </form>
                  ) : (
                    <ConfirmationText>
                      Password Reset successfully. Please login again to access
                      the application.
                    </ConfirmationText>
                  )}
                </Right>
              </Wrapper>
            </Form>
          </Col>
        </Row>
        <MessagePopup
        showModal={!!error}
        toggle={() => setError("")}
        message={error}
      />
      </Container>
    </Document>
  );
};

export default ResetPassword;
