
import CommonUtility from 'utility/common';
import { APIPath } from '../constants/api';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Cards extends CRUDService {

    constructor() {
        super(APIPath.cards)
    }

    my(params) {
        const url = `${APIPath.myCards}?${CommonUtility.objectToParams(params)}`;
        return BaseService.get(url);
    }

    sell(id,price) {
        return BaseService.put(`${APIPath.sellCard}/${id}/${price}`,null);
    }

    cancel(id) {
        return BaseService.put(`${APIPath.sellCard}/${id}/cancel`,null);
    }

    buy(id,reqData) {
        return BaseService.post(`${APIPath.buyCard}/${id}`,reqData);
    }

    history(id) {
        return BaseService.get(`${APIPath.cardHistory}/${id}`);
    }

    featured() {
        return BaseService.get(APIPath.featuredCard);
    }

    otp(id) {
        return BaseService.get(`${APIPath.cards}/${id}/otp`);
    }

    canSell() {
        return BaseService.get(APIPath.canSell);
    }
    


}

const CardsService = new Cards();
Object.freeze(CardsService);
export { CardsService };
