import { useEffect, useState } from "react";
import { UserService } from "utility/services";
import { CommonConstant, ErrorMessages } from "utility/constants/common";

export const HistoryHook = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({
    page: 0,
    size: CommonConstant.defaultPageSize,
    sort: "createdDate,desc",
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await UserService.history(filter);
      setData(data.concat(result || []));
      setHasMore((result || []).length === filter.size);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter) {
      fetchData();
    }
  }, [filter]);

  const refreshData = () => {
    fetchData();
  };

  const pageChanged = () => {
    const temp = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter({ ...temp });
  };

  return { data, loading, error, pageChanged, hasMore, refreshData };
};

export const BrandingHook = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await UserService.branding();
      UserService.storeTenant(result?.id);
      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const HistoryByIdHook = (transactionId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await UserService.historyById(transactionId);
      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    if (transactionId) {
      fetchData();
    }
  };

  return { data, loading, error, refreshData };
};
