import React, { Fragment, useEffect, useState } from "react";
import Header from "components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import ForgotPassowrd from "screens/ForgotPassowrd";
import Login from "screens/Login";
import { BrandingContextHook } from "context/branding";
import { ProtectedRoute } from "context/auth";
import { ToastContainer } from "react-bootstrap"; //import { ToastContainer } from "react-toastify";
import ResetPassword from "screens/ResetPassowrd";
import Register from "screens/Register";
import Releases from "screens/Releases";
import Payment from "screens/Payment";
import Footer from "components/Footer";
import PaymentConfirmation from "screens/app/PaymentConfirmation";
// import NftDetail from "screens/app/NftDetails";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import MyNfts from "screens/app/MyNfts";
import { ScrollToTop } from "components/ScrollToTop";
import { Play } from "screens/app/Play";
import { GlobalStyles } from "global";
import { LoadingBar } from "components/LoadingBar";

const App = () => {
  console.warn = () => {};
  const { data: branding } = BrandingContextHook();
  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    if (branding && branding?.stipePublishableKey) {
      setStripePromise(loadStripe(branding?.stipePublishableKey));
    }
  }, [branding]);

  return branding?.stipePublishableKey && stripePromise ? (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Header />
        <Elements stripe={stripePromise}>
          <Fragment>
            <ScrollToTop />

            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/register" element={<Register />} />

              <Route path="/forgot-password" element={<ForgotPassowrd />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/release" exact element={<Releases />} />
              <Route
                path="app"
                element={
                  <ProtectedRoute redirectPath="/">
                    <Outlet />
                  </ProtectedRoute>
                }
              >
                <Route index element={<MyNfts />} />
                {/* <Route path="nft-details/:id" element={<NftDetail />} /> */}
                <Route
                  path="payment-confirmation"
                  element={<PaymentConfirmation />}
                />
                <Route path="play/:id" element={<Play />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Route>
            </Routes>
          </Fragment>
        </Elements>
        <Footer />

      </Router>
      <ToastContainer />
    </ThemeProvider>
  ) : (
    <LoadingBar dark />
  );
};

export default App;
