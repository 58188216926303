


import { APIPath } from '../constants/api';
import { BaseService } from './base';

class Payment {

    add(token) {
        return BaseService.post(`${APIPath.addPayment}?token=${token}`);
    }

    savedCard() {
        return BaseService.get(APIPath.savedCard);
    }

    cashout(amount) {
        return BaseService.get(`${APIPath.cashout}?amount=${amount}`);
    }

    cryptoCharge(chargeId) {
        return BaseService.get(`${APIPath.cryptoCharge}/${chargeId}`);
    }
}

const PaymentService = new Payment();
Object.freeze(PaymentService);
export { PaymentService };
