import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { PackTemplatesHook } from "hooks/pack-templates";
import { ErrorMessageBox } from "components/EmptyState";
import styled from "styled-components";
import Pack from "page-components/Releases/PackCard";
import { LoadingBar } from "components/LoadingBar";
import { Heading } from "elements/Common";
const SubHeader = styled.div`
  background: transparent
    linear-gradient(
      270deg,
      ${({ theme }) => theme.tertiaryGreen} 20%,
      ${({ theme }) => theme.tertiaryBlue} 80%
    )
    0% 0% no-repeat padding-box;
  padding: 14rem 5rem 2rem 5rem;
  margin-bottom: 1.6rem;
  @media (max-width: 668px) {
    padding: 11rem 1rem 1rem 1rem;
  }
`;
const CardContainer = styled(Row)`
  padding: 0rem 4rem;
  margin-bottom: 5rem;
  --bs-gutter-x: 0rem;
  @media (max-width: 668px) {
    padding: 0rem 0.2rem;
  }
`;
// const HighlightContainer = styled.div`
//   margin-top: 1rem;
//   background: transparent
//     linear-gradient(
//       270deg,
//       ${({ theme }) => theme.tertiaryGreen} 20%,
//       ${({ theme }) => theme.tertiaryBlue} 80%
//     )
//     0% 0% no-repeat padding-box;
// `;
// const Content = styled.div`
//   padding: 3rem 5rem;
//   @media (max-width: 668px) {
//     padding: 1rem 1rem 4rem 1rem;
//   }
//   & p {
//     text-align: left;
//     color: ${({ theme }) => theme.white};
//     font-size: 25px;
//     margin-bottom: 0rem;
//     @media (max-width: 668px) {
//       font-size: 18px;
//     }
//   }
//   & span {
//     color: ${({ theme }) => theme.white};
//     font-size: 25px;
//     @media (max-width: 668px) {
//       font-size: 18px;
//     }
//   }
// `;
const Releases = () => {
  const { data, loading } = PackTemplatesHook();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  return (
    <>
      <Row className="gx-0">
        <Col md={12} sm={12} lg={12}>
          <SubHeader>
            <Heading color="white">DONATE MORE FOR EXCLUSIVE PoPs</Heading>
          </SubHeader>
        </Col>
      </Row>
      <CardContainer>
        {loading ? (
          <LoadingBar dark />
        ) : (
          filteredData?.map((item) => (
            <Col lg={4} md={6} xs={12} sm={12} key={item?.id}>
              <Pack item={item} />
            </Col>
          ))
        )}

        {data.length === 0 && !loading && (
          <div className="my-5">
            <ErrorMessageBox>
              <h3 className="text-center ">Coming Soon, stay tuned</h3>
            </ErrorMessageBox>
          </div>
        )}
      </CardContainer>
    </>
  );
};

export default Releases;
