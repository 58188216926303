export const Images = {
  logo: {
    main_logo: require("./logo.svg").default,
  },
  header: {
    facebook: require("./facebook.svg").default,
    twitter: require("./twitter.svg").default,
    instagram: require("./insta.svg").default,
    linkedin: require("./linkedin.svg").default,
  },
  ball: require("./ball.svg").default,
  vsl: require("./vsl.svg").default,

  payment: {
    visa: require("./visa.svg").default,
    discover: require("./discover.svg").default,
    moneygram: require("./master.svg").default,
  },
  digitalMediaAssets: {
    firstPitch: require("./PUI_social_1.jpg"),
    secondPitch: require("./PUI_social_2.jpg"),
    thirdPitch: require("./PUI_social_3.jpg"),
    fourthPitch: require("./PUI_social_4.jpg"),
    fifthPitch: require("./PUI_social_5.jpg"),
    sixthPitch: require("./PUI_IG_story-format.jpg"),
    cardLogo: require("./card_logo.svg").default
  },
  hamburger: require("./hamburger.svg").default,
  card: require("./default-pack.png"),
  defaultPack: require("./default-pack.png"),
  transfer: require("./transfer.svg").default,
  share: require("./share.svg").default,
};
