import { Images } from "img";
import { ClassColors, popIdsBasedOnClass } from "./constants/common";
import { saveAs } from "file-saver";
import JSZipUtils from "utility/JsZipUtils";

export default class CommonUtility {
  static currencyFormat = (value, currency) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency || "USD",
      minimumFractionDigits: 0,
    }).format(value || 0);

  static getZip = (arrayOfFile, zipFilename) => {
    try {
      var JSZip = require("jszip");
      let zip = new JSZip();
      let count = 0;
      arrayOfFile.forEach(function (url, i) {
        var filename = arrayOfFile[i];
        filename = filename
          // eslint-disable-next-line no-useless-escape
          // .replace(/[\/\*\|\:\<\>\?\"\\]/gi, "")
          .replace("/static/media/", "");
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(`PIFU_Social_${filename.split(".")[0]}.png`, data, {
            binary: true,
          });
          count++;
          if (count === arrayOfFile.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
            });
          }
        });
      });
    } catch (error) {}
  };

  static isNotEmpty = (item) =>
    item !== undefined && item !== null && item.length !== 0;

  static truncateString = (text, ellipsisString) =>
    (text || "").length > ellipsisString
      ? text.substring(0, ellipsisString) + "..."
      : text;

  static objectToParams = (obj) => {
    let str = "";
    for (const key in obj) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
    return str;
  };
  static getDetailsFromJson = (json, key) => {
    try {
      return JSON.parse(json)[key];
    } catch (error) {
      return "";
    }
  };
  static getClassTheme = (Class) => {
    if (!Class) return { class: "", color: "#FFFFFF", fontColor: "#1962A9" };
    const theme = ClassColors.find((theme) => theme.class === Class);
    if (!theme) return { class: "", color: "#FFFFFF", fontColor: "#1962A9" };
    return theme;
  };
  static getPopId = (Class, key) => {
    if (!Class || !key) return "";
    let classJson = popIdsBasedOnClass[Class];
    if (!classJson) return "";
    return classJson[key];
  };
  static getCardAssetImage = (asset) =>
    Images.assetType[(asset || "").toLowerCase()] || Images.assetType.stream;
}
