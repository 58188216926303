import React, { createContext, useEffect, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserService } from "utility/services";
import { BrowserUtility } from "utility/browser-utility";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadToken = () => {
      setIsAuthenticated(UserService.isAuthenticated());
      setUser(UserService.getUser());
      setLoading(false);
    };
    loadToken();
  }, []);

  const login = (data) => {
    setIsAuthenticated(true);
    setUser(data);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    BrowserUtility.remove("token");
    BrowserUtility.remove("user");
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, isAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthHook = () => useContext(AuthContext);

export const ProtectedRoute = ({ redirectPath = "/", children }) => {
  const { isAuthenticated } = AuthHook();
  console.log(UserService.isAuthenticated(),"private")
  if (!UserService.isAuthenticated()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
