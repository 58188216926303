


import { APIPath } from '../constants/api';
import { CRUDService } from './crud';

class Video extends CRUDService {

    constructor() {
        super(APIPath.video)
    }
}

const VideoService = new Video();
Object.freeze(VideoService);
export { VideoService };
