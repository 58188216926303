import styled from "styled-components";

export const SubHeading = styled.p`
  font-size: 1.875rem;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.subHeading};
`;
export const Heading = styled.h1`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "4.375rem")};
  text-transform: ${({ noTransform }) => (noTransform ? "" : "uppercase")};
  color: ${({ theme, color }) => (color ? color : theme.heading)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "4.375rem")};
  width: 100%;
  letter-spacing: 0.126;
  font-weight: 500;
  @media (max-width: 1024px) {
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "6rem")};
  }

  @media (max-width: 668px) {
    font-size: 3rem;
    line-height: 3rem;
    text-align: ${({ centered }) => (centered ? "center" : "left")};
  }
`;
export const ErrorMsg = styled.label`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.danger};
  text-align: left;
`;
export const Text = styled.p`
  text-align: left;
  font-size: 1.563rem;
  color: ${({ theme }) => theme.heading};
  text-align: left;

`;

export const Frame = styled.div`
  width: 100%;
  position: relative;
  padding: 0.5rem;
  padding-bottom: 0.8rem;
  border-radius: 20px;
  background-color: ${({ bg }) => bg && bg};
  @media (max-width: 668px) {
    min-height: initial;
  }
`;
export const Title = styled.h2`
  font-family: Acumin-Pro-Extra-Condensed !important;
  color: ${({ theme, color }) => (color ? color : theme.heading)};
  font-size: 1.563rem;
  line-height: 1.563rem;
  margin: 0rem;
 
`;

export const CardFooter = styled.div`
  width: 100%;
  display: flex;
  padding: 0rem 0.15rem;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
`;
export const Category = styled.h3`
  font-size: 1.2rem !important;
  line-height: 1.2rem;
  font-family: Helvetica-Neue-Bold, sans-serif !important ;
  color: ${({ theme, color }) => (color ? color : theme.heading)};
  margin: 0rem;
  padding: 0rem;
  @media (max-width: 668px) {
    font-size: 1rem !important;
  }
`;
export const Range = styled.p`
  font-size: 0.8rem;
  color: ${({ theme, color }) => (color ? color : theme.heading)};
  margin: 0rem;
  padding: 0rem;
  text-align: right;
  font-weight: bold;
  @media (max-width: 668px) {
    font-size: 0.6rem;
  }
`;
export const ID = styled(Range)`
  text-align: left;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Image = styled.img`
  width: 4.5rem;
  height: auto;
  position: absolute;
  bottom: -20px;
`;
