import styled from "styled-components";

export const Button = styled.button`
  background-color: ${({ bordered, theme }) =>
    bordered ? "transparent" : `${theme.button.background}`};
  color: ${({ bordered, theme }) =>
    bordered ? `${theme.button.background}` : `${theme.button.color}`};
  width: ${({ width }) => (width ? width : "100%")};
  font-size: 1.563rem;
  padding: 0.7rem;
  float: ${({ float }) => float && float};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ rounded }) => (rounded ? "30px" : "12px")};
  border: ${({ bordered, theme }) =>
    bordered ? `2px solid ${theme.button.background}` : `2px solid ${theme.button.background}`};
  white-space: nowrap;
  text-transform: ${({ noTransform }) => (noTransform ? "" : "capitalize")};
  &:hover {
    background-color: ${({ bordered, theme }) =>
      bordered ? `${theme.white}` : `${theme.tertiaryGreen}`};
       border: ${({ bordered, theme }) =>
    bordered ? `2px solid ${theme.button.background}` : `2px solid ${theme.tertiaryGreen}`};
  }
  @media (max-width: 1142px) {
    width: 100% !important;
  }
  @media (max-width: 669px) {
    width: 100% !important;
  }
`;
