import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { IoIosWarning } from "react-icons/io";
import styled from "styled-components";
import { Button } from "elements/Button";
const MessageModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
  }
  top: 30%;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5) !important;
  background-color: "transaprent" !important;
  border-radius: 15px !important;
`;

const MessageModalBody = styled(ModalBody)`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 15px !important;
`;
const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 0.5;
  font-family: Helvetica-Neue-Bold, sans-serif !important;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.white};
  text-align: center;
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.white};
  text-align: center;
  word-wrap: break-word;
  /* line-break: anywhere; */
`;
const FooterButton = styled(Button)`
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ secondary, theme }) => secondary && theme.primary};
  border-radius: 8px !important;
  margin-bottom: 1.7rem;

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ secondary, theme }) => secondary && theme.primary};
    border-radius: 8px !important;
  }
`;

export const MessagePopup = ({ showModal, toggle, title, message }) => (
  <div>
    <MessageModal isOpen={showModal} toggle={toggle}>
      <MessageModalBody>
        <div>
          <p className="text-center">
            <IoIosWarning color="#ffffff" size="60px" />
          </p>
          <Title className="my-4">Oops!</Title>

          <Description className="my-4">{message}</Description>

          <div className="d-flex justify-content-center">
            <FooterButton secondary width="60%" onClick={toggle}>
              {" "}
              Dismiss{" "}
            </FooterButton>
          </div>
        </div>
      </MessageModalBody>
    </MessageModal>
  </div>
);
