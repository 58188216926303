import { useEffect, useState } from "react";
import { CardsService } from "utility/services";
import { ErrorMessages } from "utility/constants/common";

export const CardsHook = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.my(filter);
      setData(data.concat(result || []));
      setHasMore((result || []).length === filter.size);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter) {
      fetchData();
    }
  }, [filter]);

  const pageChanged = () => {
    if (!hasMore) return;
    const temp = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter({ ...temp });
  };

  return { data, loading, error, pageChanged, hasMore };
};

export const CardDetailsHook = (id) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.getById(id);
      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, setData };
};

export const CardHistoryHook = (id) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.history(id);
      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error };
};

export const FeaturedCardHook = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.featured();
      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const CardVideoOTPHook = (id) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const result = await CardsService.otp(id);
        setData(result);
      } catch (error) {
        setError(error.message || ErrorMessages.default);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetch();
    }
  }, [id]);

  return { data, loading, error };
};

export const CanSellHook = () => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      await CardsService.canSell();
      setData(true);
    } catch (error) {
      setData(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading };
};

export const usePopIds = (url) => {
  const [popIds, setPopIds] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      await fetch("https://greta-public-1.s3.amazonaws.com/PIFU-POP/silver.json")
      setPopIds(true);
    } catch (error) {
      setPopIds(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return { popIds, loading };
};
