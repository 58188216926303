import React, { forwardRef } from "react";
import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import styled from "styled-components";
import { ErrorMsg } from "./Common";
import { BsEye, BsEyeSlash } from "react-icons/bs";

export const TextField = styled.input`
  width: 100%;
  padding: 0.6rem 1rem;
  border: none;
  font-size:1.563rem;
  outline: none;
&::placeholder{
  font-family: Helvetica !important;
  color: ${({ theme }) => theme.input.placeholder};

}
`;
export const IconContainer = styled.div`
  cursor: pointer;
  padding-right: 1rem;
`;
export const Icon = styled.span``;
const TextFieldContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid
    ${({ isError, theme }) => (isError ? theme.danger : theme.input.border)};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }
`;
export const MobileTextField = styled(PhoneInputWithCountry)`
  border: 1px solid
    ${({ isError, theme }) => (isError ? theme.danger : theme.grey)} !important;
  padding: 0.6rem 1rem !important;
  width: 100% !important;
  font-family: Helvetica-Regular !important;
  font-size:1.563rem;

  &:focus {
    outline: none !important;
  }
  &::placeholder {
    color: ${({ theme }) => theme.placeholder} !important;
    font-family: Helvetica-Regular !important;
  }
  input {
    border: none !important;
  }
  input::placeholder {
    color: ${({ theme }) => theme.placeholder} !important;
    font-family: Helvetica-Regular !important;
  }
  input:focus {
    outline: none !important;
  }
`;

export const Input = forwardRef(
  (
    { name, type, placeholder, errors, showPassword, setShowPassword, ...rest },
    ref
  ) => (
    <>
      <TextFieldContainer isError={errors?.message}>
        <TextField
          name={name}
          type={showPassword ? "text" : type}
          placeholder={placeholder}
          ref={ref}
          autoComplete="off"
          {...rest}
        />
        {type === "password" && (
          <IconContainer
            onClick={(e) => {
              setShowPassword(!showPassword);
            }}
          >
            <Icon>
              {showPassword ? (
                <BsEye color="#000000" />
              ) : (
                <BsEyeSlash color="#000000" />
              )}
            </Icon>
          </IconContainer>
        )}
      </TextFieldContainer>
      {errors?.message && <ErrorMsg>{errors?.message} </ErrorMsg>}
    </>
  )
);

export const MobileFormField = ({
  control,
  name,
  placeholder,
  errors,
  ...rest
}) => (
  <>
    <MobileTextField
      name={name}
      placeholder={placeholder}
      control={control}
      limitMaxLength={true}
      isError={errors}
      {...rest}
    />
    {errors?.message && <ErrorMsg>{errors?.message} </ErrorMsg>}
  </>
);
