import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserService } from "utility/services";
import { AuthHook } from "context/auth";
import { ErrorMessages } from "utility/constants/common";
import { Button } from "elements/Button";
import { Input } from "elements/Input";
import Document from "layout/Document";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { Heading, SubHeading, Text } from "elements/Common";
import { MessagePopup } from "components/MessagePopup";

const Form = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  @media (max-width: 668px) {
    padding: 0rem;
  }
`;
const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
`;
const Links=styled(Link)`
font-size: 1.563rem !important;
`;
const Container = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Right = styled.div`
  width: 100%;
  padding: 4rem 9rem 1rem 4rem;
  min-height: 93vh;
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    min-height: 65vh;
    height: auto;
  }
  @media (max-width: 668px) {
    margin-bottom: 2rem;
  }
`;
const InputWrapper = styled.div`
  padding: 0.5rem 0rem;
`;
const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  min-height: 93vh;
  height: 100%;
  padding: 4rem 6rem 1rem 6rem;
  background-color: ${({ theme }) => theme.secondary};

  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    padding-top: 8rem;
    min-height: 65vh;
    height: auto;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const loginSchema = yup.object().shape({
  username: yup.string().required("Email address is required."),
  password: yup.string().required("Password is required."),
  rememberMe: yup.boolean(),
});
const Login = () => {
  const {
    register: login,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const { login: loginContext } = AuthHook();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState("");

  const afterLogin = (result) => {
    UserService.storeToken(result.id_token);

    setTimeout(async () => {
      const user = await UserService.get();
      UserService.storeUser(user);
      loginContext(user);
      navigate("/app");
    });
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const req = {
        ...data,
      };

      const result = await UserService.login(req);
      afterLogin(result);
    } catch (err) {
      const error =
        err?.error?.detail === "Bad credentials"
          ? ErrorMessages.badCredentials
          : err?.error?.detail;
      setError(error || ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Document title="login">
      <Container>
        <Row className="gx-0 w-100">
          <Col lg={6} md={6} sm={12} xs={12}>
            <Left>
              <Content>
                <SubHeading>Log In</SubHeading>
                <Heading>Login to your Pitch IN for Ukraine Account</Heading>
              </Content>
            </Left>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Form>
              <Wrapper>
                <Right>
                  <Text>
                    If you don’t have an account, &nbsp;
                    <br />
                    <Link className="me-2" to="/register">
                      click here
                    </Link>
                    to create one.
                  </Text>
                  <form
                    name="formLogin"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <InputWrapper>
                      <Input
                        {...login("username")}
                        errors={errors?.username}
                        placeholder="Email Address"
                        name="username"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="password"
                        {...login("password")}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        errors={errors?.password}
                        placeholder="Password"
                        name="password"
                      />
                    </InputWrapper>
                   
                    <Links to="/forgot-password"> Forgot password?</Links>
                    <ButtonWrapper>
                      <Button type="submit" width="50%">
                        {loading ? "Loading..." : "Log In"}
                      </Button>
                    </ButtonWrapper>
                  </form>
                </Right>
              </Wrapper>
            </Form>
          </Col>
        </Row>
        <MessagePopup
        showModal={!!error}
        toggle={() => setError("")}
        message={error}
      />
      </Container>
    </Document>
  );
};

export default Login;
