import React, { useMemo } from "react";
import styled from "styled-components";
import { AuthHook } from "context/auth";
import CommonUtility from "utility/common";
import { Category, Frame, ID, Title, Range } from "elements/Common";

const CardImage = styled.img`
  cursor: pointer;
  width: 100%;
  margin-bottom: 0.6rem;
`;

const CardFooter = styled.div`
  width: 100%;
  display: flex;
  padding: 0rem 0.15rem;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
`;

const Nft = ({
  maxExisting,
  imageUrl,
  groupSequence,
  id,
  // classUrl,
  Class,
}) => {
  const { user } = AuthHook();
  // const [hoverText, setHoverText] = useState("Share This NFT");
  // const navigate = useNavigate();
  const handleClick = (id) => {
    // navigate(`/app/nft-details/${id}`);
  };
  // const copyToClipboard = () => {
  //   const textArea = document.createElement("textarea");
  //   textArea.value = `${window.location.href}/nft-details/${id}`;
  //   document.body.appendChild(textArea);
  //   textArea.select();
  //   document.execCommand("Copy");
  //   textArea.remove();
  //   setHoverText("Copied to clipboard");
  //   setTimeout(() => {
  //     setHoverText("Share This NFT");
  //   }, 1500);
  // };

  const classTheme = useMemo(() => CommonUtility.getClassTheme(Class), [Class]);
  return (
    <div className="mt-3 px-4">
      <Frame onClick={() => handleClick(id)} bg={classTheme?.color}>
        <CardImage src={imageUrl} alt="PoP" className="img img-fluid" />
        <CardFooter>
          <div>
            <Title color={classTheme?.fontColor}>
              {user?.firstName} {/* <br /> */}
              {user?.lastName}
            </Title>
            <ID color={classTheme?.fontColor}>
              PoP {CommonUtility.getPopId(Class, groupSequence)}
            </ID>
          </div>
          <div>
            <Category color={classTheme?.fontColor}>{Class}</Category>
            <Range color={classTheme?.fontColor}>
              {groupSequence}/{maxExisting}
            </Range>
          </div>
        </CardFooter>
        {/* <Logo>
          <Image src={Images?.logo?.main_logo} />
        </Logo> */}
      </Frame>

      {/* <Button className="mt-4" onClick={copyToClipboard}>
        {hoverText}
      </Button> */}
    </div>
  );
};

export default Nft;
