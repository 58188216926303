import React, { useState, useMemo, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { Button } from "elements/Button";
import { Images } from "img";
import styled from "styled-components";
import { CardsHook } from "hooks/cards";
import { CardAssetTypes } from "utility/constants/common";
import Nft from "page-components/MyNfts/Nft";
import { ErrorMessageBox } from "components/EmptyState";
import { Heading } from "elements/Common";
import Carousel from "react-elastic-carousel";
import CommonUtility from "utility/common";
import ReactPlayer from "react-player/lazy";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { LinkButton } from "elements/LinkButton";
const Slider = styled(Carousel)`
  .rec-arrow {
    display: none;
  }
  .rec-dot {
    background-color: ${({ theme }) => theme.primary};
  }
  .rec-dot_active {
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0 0 1px 3px ${({ theme }) => theme.white};
  }
`;

const Desktop = styled(Row)`
  h4 {
    font-size: 3.75rem !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const Mobile = styled(Row)`
  @media (min-width: 768px) {
    display: none;
  }
`;
const FirstSection = styled.div`
  background: transparent
    linear-gradient(
      270deg,
      ${({ theme }) => theme.tertiaryGreen} 20%,
      ${({ theme }) => theme.tertiaryBlue} 80%
    )
    0% 0% no-repeat padding-box;
  padding: 12rem 4rem 3rem 4rem;
  @media (max-width: 668px) {
    padding: 12rem 1rem 2rem 1rem;
  }
`;
const TopSection = styled.div`
  display: flex;
  padding: 0rem 6rem;
  flex-direction: column;
  margin: 2rem 0rem;

  @media (max-width: 668px) {
    padding: 0rem 1rem;
  }
`;
const Content = styled.p`
  font-size: 1.563rem;
  color: ${({ theme }) => theme.white};
  font-weight: 400;
  @media (max-width: 668px) {
    font-size: 1.2rem;
  }
`;
const CardSection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (max-width: 668px) {
    width: 100%;
  }
  h4 {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.white};
    margin-bottom: 0rem;
    @media (max-width: 1023px) {
      font-size: 2.2rem;
    }
    @media (max-width: 668px) {
      font-size: 2rem;
    }
  }
`;
const SocialMediaSection = styled.div`
  min-height: 100vh;
  height: auto;
  background-color: ${({ theme }) => theme.primary};
  padding: 6rem 4rem 3rem 4rem;
  @media (max-width: 668px) {
    padding: 2rem 1rem 2rem 1rem;
  }
`;

const PitchIn = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
`;

// const CoverImgCircular = styled.div`
//   margin-left: 4rem;
//   width: 60%;
//   height: auto;
//   overflow: hidden;
//   border-radius: 50%;
//   @media (max-width: 992px) {
//     width: 100%;
//     height: 100%;
//     margin-left: 0rem;
//   }
// `;
// const CircularImg = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
// `;
const CoverImg = styled.img`
  width: inherit;
  height: inherit;
  object-fit: fill;
  display: block;
`;

const GalleryPopup = styled(Modal)`
  .modal-content {
    background: transparent
      linear-gradient(
        270deg,
        ${({ theme }) => theme.tertiaryBlue} 20%,
        ${({ theme }) => theme.tertiaryGreen} 80%
      )
      0% 0% no-repeat padding-box;
    backdrop-filter: blur(65px);
    padding: 4rem 2rem;
  }
  .modal-header {
    padding: 0;
    border: 0;
  }
  .btn-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
  .modal-body {
    padding: 0;
    border: 0;
    .react-player {
      width: 100% !important;
    }
  }
`;
function VideoPlayer(props) {
  return (
    <GalleryPopup {...props} size="lg" centered>
      <ModalHeader closeButton />
      <ModalBody>
        <ReactPlayer
          url="https://res.cloudinary.com/on-chain/video/upload/v1661200803/Trubin_Pyatov_Traore_-_Thank_You_Comperssed_saushu.mp4"
          playing
          controls
          className="react-player"
        />
      </ModalBody>
    </GalleryPopup>
  );
}
const MyNfts = () => {
  const [nfts, setNfts] = useState([]);
  const { data, loading } = CardsHook();
  const [modalShow, setModalShow] = useState(false);

  useMemo(() => {
    setNfts(data);
  }, [data]);
  const toggle = () => setModalShow(!modalShow);
  const filterAssets = (assetType) => {
    return nfts?.filter((nft) => nft?.assetType === assetType);
  };

  const handleDownload = async () => {
    let zipFilename = "Pitches.zip";
    let pitches = [
      Images.digitalMediaAssets.firstPitch,
      Images.digitalMediaAssets.secondPitch,
      Images.digitalMediaAssets.thirdPitch,
      Images.digitalMediaAssets.fourthPitch,
      Images.digitalMediaAssets.fifthPitch,
      Images.digitalMediaAssets.sixthPitch

    ];
    CommonUtility.getZip(pitches, zipFilename);
  };
  return (
    <>
      <FirstSection>
        <Heading className="px-3" color="white" noTransform>
          YOUR POPS
        </Heading>
        <TopSection>
          <Row className=" px-0 px-sm-4 ">
            <Col lg={7} md={12} sm={12}>
              <Content>
                Welcome to your account. Here you will be able to view and
                export your digital collectibles as well as view special content
                relating to how your donation has made a positive impact on the
                people of Ukraine. Thank you again for pitching in for Ukraine!
              </Content>
            </Col>
            <Col lg={5} md={12} sm={12}>
              <Button
                float="right"
                noTransform
                width="70%"
                onClick={() => setModalShow(true)}
              >
                Watch the Video
              </Button>
              <LinkButton
                float="right"
                noTransform
                width="70%"
                className="my-2"
                href="https://www.pitchinforukraine.org/teams/"
              >
                Donate Again
              </LinkButton>
            </Col>
          </Row>
          {filterAssets(CardAssetTypes.stream) &&
            filterAssets(CardAssetTypes.stream)?.length !== 0 && (
              <CardSection>
                <Desktop className="w-100">
                  <h4 className="mb-3">STREAMS</h4>
                  {filterAssets(CardAssetTypes.stream)?.map((item) => (
                    <Col lg={4} md={6} sm={12} xs={12} key={item?.id}>
                      <Nft item={item} />
                    </Col>
                  ))}
                </Desktop>
                <Mobile className="w-100">
                  <h4 className="mb-3 mt-3">STREAMS</h4>
                  <Slider>
                    {filterAssets(CardAssetTypes.stream)?.map((item) => (
                      <Col lg={4} md={6} sm={12} xs={12} key={item?.id}>
                        <Nft item={item} />
                      </Col>
                    ))}
                  </Slider>
                </Mobile>
              </CardSection>
            )}
          {filterAssets(CardAssetTypes.image) &&
            filterAssets(CardAssetTypes.image)?.length !== 0 && (
              <CardSection>
                <Desktop className="w-100">
                  <h4 className="mb-3 ps-4">GIFs</h4>
                  {filterAssets(CardAssetTypes.image)?.map((item, i) => (
                    <Col xl={4} lg={6} md={6} sm={12} xs={12} key={i}>
                      <Nft
                        classUrl={item?.text}
                        maxExisting={item?.maxExisting}
                        imageUrl={item?.icon?.url}
                        groupSequence={item?.groupSequence}
                        key={item?.id}
                        id={item.id}
                        Class={item?.classLabel}
                      />
                    </Col>
                  ))}
                </Desktop>
                <Mobile className="w-100">
                  <h4 className="mb-3">GIFs</h4>
                  <Slider>
                    {filterAssets(CardAssetTypes.image)?.map((item, i) => (
                      <Col lg={4} md={6} sm={12} xs={12} key={i}>
                        <Nft
                          classUrl={item?.text}
                          maxExisting={item?.maxExisting}
                          imageUrl={item?.icon?.url}
                          groupSequence={item?.groupSequence}
                          key={item?.id}
                          id={item.id}
                          Class={item?.classLabel}
                        />
                      </Col>
                    ))}
                  </Slider>
                </Mobile>
              </CardSection>
            )}
          {filterAssets(CardAssetTypes.image) &&
            filterAssets(CardAssetTypes.image)?.length !== 0 && (
              <CardSection>
                <Desktop className="w-100">
                  <h4 className="mb-3 ps-4">JPGs</h4>
                  {filterAssets(CardAssetTypes.image)?.map((item, i) => (
                    <Fragment key={i}>
                      {item?.attachments?.map((gif, i) => (
                        <Col xl={4} lg={6} md={6} sm={12} xs={12} key={i}>
                          <Nft
                            classUrl={item?.text}
                            maxExisting={item?.maxExisting}
                            imageUrl={gif?.url}
                            groupSequence={item?.groupSequence}
                            key={item?.id}
                            id={item.id}
                            Class={item?.classLabel}
                          />
                        </Col>
                      ))}
                    </Fragment>
                  ))}
                </Desktop>
                <Mobile className="w-100">
                  <h4 className="mb-3 mt-3">JPGs</h4>
                  <Slider>
                    {filterAssets(CardAssetTypes.image)?.map((item, i) => (
                      <Fragment key={i}>
                        {item?.attachments?.map((gif, i) => (
                          <Col lg={4} md={6} sm={12} xs={12} key={i}>
                            <Nft
                              classUrl={item?.text}
                              maxExisting={item?.maxExisting}
                              imageUrl={gif?.url}
                              groupSequence={item?.groupSequence}
                              id={item.id}
                              Class={item?.classLabel}
                            />
                          </Col>
                        ))}
                      </Fragment>
                    ))}
                  </Slider>
                </Mobile>
              </CardSection>
            )}

          {filterAssets(CardAssetTypes.illustration) &&
            filterAssets(CardAssetTypes.illustration)?.length !== 0 && (
              <CardSection>
                <Desktop className="w-100">
                  <h3 className="mb-3">ILLUSTRATIONS</h3>
                  {filterAssets(CardAssetTypes.illustration)?.map((item) => (
                    <Col key={item?.id} lg={4} md={6} sm={12} xs={12}>
                      <Nft item={item} />
                    </Col>
                  ))}
                </Desktop>
                <Mobile className="w-100">
                  <h3 className="mb-3 mt-3">ILLUSTRATIONS</h3>
                  <Slider>
                    {filterAssets(CardAssetTypes.illustration)?.map((item) => (
                      <Col key={item?.id} lg={4} md={6} sm={12} xs={12}>
                        <Nft item={item} />
                      </Col>
                    ))}
                  </Slider>
                </Mobile>
              </CardSection>
            )}

          {data?.length === 0 && !loading && (
            <div className="my-5">
              <ErrorMessageBox>
                <h3 className="text-center ">
                  {" "}
                  <a
                    href="https://www.pitchinforukraine.org/teams/"
                    rel="noreferrer"
                  >
                    Click here
                  </a>{" "}
                  to Donate.
                </h3>
              </ErrorMessageBox>
            </div>
          )}
        </TopSection>
      </FirstSection>
      <SocialMediaSection>
        <Heading color="white" centered>
          SOCIAL MEDIA ASSETS
        </Heading>
        <TopSection>
          <Row className=" px-0 px-sm-4 ">
            <Col lg={7} md={12} sm={12}>
              <Content>
                We have provided a number of images that you can use to help
                show that you have supported this important campaign. Feel free
                to use any of these assets, as well as your unique PoP, to get
                the word out there for others to Pitch In For Ukraine.
              </Content>
            </Col>
            <Col lg={5} md={12} sm={12}>
              <Button width="70%" noTransform onClick={handleDownload}>
                Download Full Set
              </Button>
            </Col>
          </Row>
          <Row className=" px-0 px-sm-4 ">
            <Col lg={6} md={12} sm={12} xs={12}>
              <PitchIn>
                <CoverImg
                  src={Images.digitalMediaAssets.firstPitch}
                  alt="poster"
                />
              </PitchIn>
            </Col>
            <Col lg={6} md={12} sm={12} xs={12}>
              <PitchIn>
                <CoverImg
                  src={Images.digitalMediaAssets.secondPitch}
                  alt="poster"
                />
              </PitchIn>
            </Col>
          </Row>
          {/* <Row className=" px-0 px-sm-4 ">
            <Col lg={5} md={12} sm={12} xs={12}>
              <PitchIn>
                <CoverImg
                  src={Images.digitalMediaAssets.firstPitch}
                  alt="poster"
                />
              </PitchIn>
            </Col>
            <Col
              className="d-flex align-items-end"
              lg={7}
              md={12}
              sm={12}
              xs={12}
            >
              <PitchIn>
                <CoverImgCircular>
                  <CircularImg
                    src={Images.digitalMediaAssets.secondPitch}
                    alt="poster"
                  />
                </CoverImgCircular>
              </PitchIn>
            </Col>
          </Row> */}
          <Row className=" px-0 px-sm-4 ">
            <Col lg={6} md={12} sm={12} xs={12}>
              <PitchIn>
                <CoverImg
                  src={Images.digitalMediaAssets.thirdPitch}
                  alt="poster"
                />
              </PitchIn>
            </Col>
            <Col lg={6} md={12} sm={12} xs={12}>
              <PitchIn>
                <CoverImg
                  src={Images.digitalMediaAssets.fourthPitch}
                  alt="poster"
                />
              </PitchIn>
            </Col>
          </Row>
          {/* <Row className=" px-0 px-sm-4 ">
            <Col>
              <PitchIn>
                <CoverImg
                  src={Images.digitalMediaAssets.thirdPitch}
                  alt="banner"
                />
              </PitchIn>
            </Col>
          </Row> */}
          <Row className=" px-0 px-sm-4 ">
            <Col lg={6} md={12} sm={12} xs={12}>
              <PitchIn>
                <CoverImg
                  src={Images.digitalMediaAssets.fifthPitch}
                  alt="poster"
                />
              </PitchIn>
            </Col>
            <Col lg={6} md={12} sm={12} xs={12}>
              <PitchIn>
                <CoverImg
                  src={Images.digitalMediaAssets.sixthPitch}
                  alt="poster"
                />
              </PitchIn>
            </Col>
          </Row>
        </TopSection>
      </SocialMediaSection>
      {/* <WhiteBox></WhiteBox> */}
      <VideoPlayer isOpen={modalShow} toggle={toggle} />
    </>
  );
};

export default MyNfts;
