import React, { useMemo, useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Input } from "../elements/Input";
import { Button } from "../elements/Button";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { DetailsHook, SavedCardHook } from "hooks/payment";
import { CardsService, PacksService } from "utility/services";
import { LoadingBar, OverlayLoadingBar } from "components/LoadingBar";
import CommonUtility from "utility/common";
import { ErrorMessages, TransactionStatus } from "utility/constants/common";
import styled from "styled-components";
import CoinbaseCommerceButton from "react-coinbase-commerce";
import { UserService } from "utility/services";
import { HistoryByIdHook } from "hooks/user";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AuthHook } from "context/auth";
import { MessagePopup } from "components/MessagePopup";
import { BrandingContextHook } from "context/branding";
import {
  CardFooter,
  Category,
  ErrorMsg,
  Frame,
  ID,
  Title,
} from "elements/Common";
import { theme } from "theme";
import DropdownSelector from "elements/Dropdown";
import { Images } from "../img/index";

const Container = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Right = styled.div`
  width: 100%;
  padding: 8rem 6rem 1rem 4rem;
  min-height: 93vh;
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    min-height: 65vh;
    height: auto;
  }
  & h2 {
    text-align: left;
    font-size: 2rem;
    color: ${({ theme }) => theme.heading};
    text-align: left;
    @media (max-width: 1023px) {
      font-size: 1.8rem;
    }
    @media (max-width: 668px) {
      font-size: 1.5rem;
    }
    & a {
      color: ${({ theme }) => theme.heading};
      font-weight: 100 !important;
    }
  }
  @media (max-width: 668px) {
    margin-bottom: 2rem;
  }
`;
const InputWrapper = styled.div`
  padding: 0.5rem 0rem;
  width: 100%;
`;
const ButtonWrapper = styled.div`
  margin-top: 1rem;
  width: 50%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 93vh;
  height: 100%;
  background-color: ${({ theme }) => theme.secondary};
  padding: 8rem 6rem 1rem 4rem;
  @media (max-width: 1023) {
    padding: 2rem !important;
  }
  @media (max-width: 668px) {
    padding: 8rem 2rem 2rem 2rem !important;
    padding-top: 8rem;
    min-height: 65vh;
    height: auto;
  }
`;
const PaymentOption = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  & button {
    margin-top: 1rem;
  }
  & h3 {
    font-size: 2rem;
    color: ${({ theme }) => theme.primary};
    margin-bottom: 0rem;
    @media (max-width: 1023) {
      text-align: center;
      font-size: 30px;
    }
    @media (max-width: 668px) {
      font-size: 26px;
      text-align: left;
      margin-bottom: 0rem;
    }
  }
`;
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  @media (max-width: 1300) {
    flex-direction: column !important;
    width: 100%;
    text-align: left;
  }
  @media (max-width: 668px) {
    flex-direction: column !important;
    width: 100%;
    text-align: left;
  }
  & h3 {
    font-size: 2.5rem !important;
    white-space: nowrap;
    color: ${({ theme }) => theme.heading};
    font-family: "Acumin-Pro-Extra-Condensed" !important;
    margin-bottom: 0rem;
    @media (max-width: 1300) {
      font-size: 2.5 !important;

      margin-bottom: 0rem;
      text-align: left !important;
      width: 100%;
    }
    @media (max-width: 668px) {
      font-size: 2.5 !important;
      margin-bottom: 0rem;
      text-align: left !important;
      width: 100%;
    }
  }
  & p {
    font-size: 1.2rem;
    margin: 0rem;
    padding: 0rem;
    @media (max-width: 1300px) {
      text-align: left;
      width: 100%;
    }
    @media (max-width: 668px) {
      text-align: left;
      width: 100%;
    }
  }
`;

const CardInput = styled.div`
  padding: 0.5rem 0rem;
  div {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.grey};
    padding: 0.8rem 1rem;
    background-color: transparent !important;
    line-height: 1;
    text-align: left;
    color: ${({ theme }) => theme.black} !important;
    padding-right: 3rem !important;
    &::placeholder,
    &::after,
    &::before {
      color: ${({ theme }) => theme.placeholder} !important;
    }
    &:focus {
      font-size: 1.125rem;
      outline: none;
      color: ${({ theme }) => theme.black} !important;
      background-color: transparent;
    }
    &.is-invalid {
      border-color: ${({ theme }) => theme.danger};
    }
  }
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 5rem;
  align-items: flex-start;
  @media (max-width: 668px) {
    padding: 3rem 0rem;
  }
  img {
    width: 100%;
  }
`;
const InformationMsg = styled(ErrorMsg)`
  color: ${({ theme }) => theme.black};
`;

const CardImage = styled.img`
  cursor: pointer;
  width: 100%;
  margin-bottom: 0.6rem;
`;
const ExistingCard = styled.p`
  font-family: Helvetica-Neue-Medium, sans-serif !important;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.placeholder};
  margin-top: 1.5rem;
`;
const CardIcon = styled.img`
  width: 2rem;
  margin-right: 1rem;
`;

const HeadingLight = styled.h3`
  color: ${({ theme }) => theme.heading} !important;
`;
const useOptions = () => {
  const fontSize = "1.563rem";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          fontWeight: "500",
          color: theme.black,
          letterSpacing: "0.025em",
          "::placeholder": {
            color: theme.input.placeholder,
          },
        },
        invalid: {
          color: theme.danger,
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const paymentSchema = yup.object().shape({
  name: yup.string().required("Card holder name is required."),
  currency: yup.string().required("Please select the currency."),
  saveCard: yup.boolean(),
});

const registerSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required.")
    .min(4, "Password should be between 4 and 100.")
    .max(100, "Password should be between 4 and 100."),
  email: yup
    .string()
    .required("Email address is required.")
    .email("Invalid email address."),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref("email"), null], "Email must match.")
    .required(),
  confirmPassword: yup
    .string()
    .required("Confirm password is required.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});
const Payment = () => {
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [refId, setRefId] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const [processing, setProcessing] = useState(false);
  const [nameIsFocus, setNameIsFocus] = useState(false);
  const [registerUserLoading, setRegisterLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(false);
  const [savedCardProcessing, setSavedCardProcessing] = useState(false);
  const [chargeCode, setChargeCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termChecked, setTermChecked] = useState(false);
  const [emailSubscribe, setEmailSubscribe] = useState(true);

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const { data: card, loading: cardLoading } = SavedCardHook();
  const { data: details, loading: detailsLoading } = DetailsHook(id, type);
  const { data: branding } = BrandingContextHook();
  const {
    data: transaction,
    error: transactionError,
    refreshData,
  } = HistoryByIdHook(transactionId);

  const {
    register: frmPayment,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(paymentSchema),
  });
  const {
    register,
    formState: { errors: registerError },
    handleSubmit: handleRegisterUser,
    watch,
  } = useForm({
    resolver: yupResolver(registerSchema),
  });
  let { search } = useLocation();
  const { login: loginContext, user } = AuthHook();
  let inputUserName = `${watch("firstName", false)} ${watch(
    "lastName",
    false
  )}`;

  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    if (id) {
      setId(id);
    }
    const type = new URLSearchParams(search).get("type");
    if (type) {
      setType(type);
    }
    const RefID = new URLSearchParams(search).get("refid");
    if (RefID) {
      setRefId(RefID);
    }
  }, [search]);

  useEffect(() => {
    const fetch = async () => {
      switch (transaction?.transactionStatus) {
        case TransactionStatus.crypto_waiting:
          setChargeCode(transaction?.cryptoChargeCode);
          break;
        case TransactionStatus.success:
          setTransactionId("");
          onTransactionComplete();
          break;

        case TransactionStatus.fail:
          setTransactionId("");
          setError(transaction?.notes);
          break;

        default:
          break;
      }
    };
    fetch();
  }, [transaction]);

  useEffect(() => {
    if (!transactionId) return;
    const interval = setInterval(() => {
      refreshData();
    }, 5000);
    return () => clearInterval(interval);
  }, [transactionId]);

  useEffect(() => {
    if (chargeCode) {
      setTimeout(() => {
        const button = document.getElementsByClassName("coinbase-button");
        if (button.length > 0) {
          button[0].click();
        }
      }, 500);
    }
  }, [chargeCode]);

  useEffect(() => {
    if (user) return setUserName(`${user?.firstName} ${user?.lastName}`);
    if (inputUserName && inputUserName !== ` `)
      return setUserName(inputUserName);
    return setUserName(
      CommonUtility.getDetailsFromJson(details?.description, "name")
    );
  }, [user, inputUserName, details]);

  useEffect(() => {
    if (cardLoading) return;
    if (!user) return setShowForm(true);
    if (card?.last4 && user) return setShowForm(false);
  }, [card, cardLoading, user]);
  const afterLogin = (result) => {
    UserService.storeToken(result.id_token);
    setTimeout(async () => {
      const user = await UserService.get();
      UserService.storeUser(user);
      loginContext(user);
    });
  };
  const registerUser = async (data) => {
    try {
      setRegisterLoading(true);
      const req = {
        ...data,
        emailSubscribe,
        login: "any",
      };
      const loginReq = {
        password: data?.password,
        username: data?.email,
      };
      await UserService.register(req);
      const result = await UserService.login(loginReq);
      afterLogin(result);
      await UserService.forgotPassword({ email: data?.email });
      setNameIsFocus(false);
    } catch (err) {
      if (err?.error?.title === ErrorMessages.BackendEmailExistError) {
        setError(ErrorMessages.proceedFail);
      } else {
        setError(err?.error?.title || ErrorMessages.default);
      }
    } finally {
      setRegisterLoading(false);
    }
  };
  const buy = async (formData) => {
    if (!termChecked) return setError(ErrorMessages.TermCheckedError);

    if (!UserService.isAuthenticated()) {
      await handleRegisterUser(registerUser)();
    }

    if (
      Object.keys(registerError).length === 0 &&
      UserService.isAuthenticated()
    ) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      setError("");
      setProcessing(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: formData.name,
        },
      });
      if (error) {
        setProcessing(false);
        setError(error.message);
      } else {
        try {
          let result;
          // await PaymentService.add(paymentMethod.id);
          if (type === "pack") {
            result = await PacksService.buy(
              id,
              {
                currency: formData.currency,
                refId,
                paymentMethodVM: {
                  paymentMethodId: paymentMethod.id,
                  saveCard: formData.saveCard,
                },
                sendFacebookEvent: false,
              },
              { autoOpenPack: true }
            );
          } else if (type === "card") {
            result = await CardsService.buy(id, {
              paymentMethodId: paymentMethod.id,
              saveCard: formData.saveCard,
            });
          }
          setTransactionId(result?.id);
        } catch (error) {
          setError(error?.error?.title || ErrorMessages.default);
        } finally {
          setProcessing(false);
        }
      }
    }
  };
  const buyUsingSavedCard = async () => {
    if (!UserService.isAuthenticated()) {
      await handleRegisterUser(registerUser)();
    }

    if (
      Object.keys(registerError).length === 0 &&
      UserService.isAuthenticated()
    ) {
      try {
        setSavedCardProcessing(true);
        let result;
        if (type === "pack") {
          result = await PacksService.buy(
            id,
            {
              sendFacebookEvent: false,
              refId,
            },
            { autoOpenPack: true }
          );
        } else if (type === "card") {
          result = await CardsService.buy(id, null);
        }
        setTransactionId(result?.id);
      } catch (error) {
        setError(ErrorMessages.default);
      } finally {
        setSavedCardProcessing(false);
      }
    }
  };
  const buyUsingCrypto = async () => {
    if (!UserService.isAuthenticated()) {
      await handleRegisterUser(registerUser)();
    }

    if (
      Object.keys(registerError).length === 0 &&
      UserService.isAuthenticated()
    ) {
      try {
        setProcessing(true);
        let result;
        if (type === "pack") {
          result = await PacksService.buy(
            id,
            {
              paymentMethodVM: { isCrypto: true },
              sendFacebookEvent: false,
              refId,
            },
            { autoOpenPack: true }
          );
        } else if (type === "card") {
          result = await CardsService.buy(id, { isCrypto: true });
        }
        setTransactionId(result?.id);
      } catch (error) {
        setError(error?.error?.title || ErrorMessages.default);
      } finally {
        setProcessing(false);
      }
    }
  };
  useEffect(() => {
    if (transactionError) {
      setError(transactionError?.error?.title || ErrorMessages.default);
      setTransactionId(null);
    }
  }, [transactionError]);
  const onTransactionComplete = async () => {
    setProcessing(false);
    setTransactionId(false);
    navigate(`/app/payment-confirmation?id=${id}&type=${type}`);
  };
  const coinBaseError = (error) => {
    setTransactionId("");
    setError(error);
  };
  const onCloseCoinBase = () => {
    setTransactionId("");
    navigate(`/payment?id=${id}&type=${type}`);
  };
  const classTheme = useMemo(
    () =>
      CommonUtility.getClassTheme(
        CommonUtility.getDetailsFromJson(details.description, "class")
      ),
    [details]
  );
  return (
    <>
      <Container>
        <Row className="gx-0 w-100">
          <Col lg={6} md={12} sm={12} xs={12}>
            <Left>
              {detailsLoading ? (
                <LoadingBar dark />
              ) : (
                <CardContainer>
                  <Frame bg={classTheme?.color} className="mb-4">
                    <CardImage
                      src={details?.picture?.url}
                      alt="PoP"
                      className="img img-fluid"
                    />
                    <CardFooter>
                      <div>
                        <Title color={classTheme?.fontColor}>{userName}</Title>

                        <ID color={classTheme?.fontColor}>
                          PoP{" "}
                          {CommonUtility.getDetailsFromJson(
                            details?.description,
                            "id"
                          )}
                        </ID>
                      </div>
                      <div>
                        <Category color={classTheme?.fontColor}>
                          {CommonUtility.getDetailsFromJson(
                            details?.description,
                            "class"
                          )}
                        </Category>
                      </div>
                    </CardFooter>
                  </Frame>
                </CardContainer>
              )}
              {!UserService.isAuthenticated() && (
                <>
                  {nameIsFocus && (
                    <InformationMsg>
                      Please note that the names you enter in First and Last
                      Name sections below will appear on your PoP. Names will be
                      engraved exactly as you enter them so take extra care when
                      typing.
                    </InformationMsg>
                  )}
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      {" "}
                      <InputWrapper>
                        <Input
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          onFocus={() => setNameIsFocus(true)}
                          {...register("firstName")}
                          errors={registerError?.firstName}
                        />
                      </InputWrapper>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <InputWrapper>
                        {" "}
                        <Input
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          onFocus={() => setNameIsFocus(true)}
                          {...register("lastName")}
                          errors={registerError?.lastName}
                        />
                      </InputWrapper>
                    </Col>
                  </Row>
                </>
              )}
            </Left>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12}>
            <Right>
              <PaymentOption>
                {branding?.displayCryptoPay && (
                  <Button
                    onClick={buyUsingCrypto}
                    disabled={processing || registerUserLoading}
                    type="submit"
                  >
                    {processing || registerUserLoading
                      ? "Loading"
                      : " Pay With Crypto"}
                  </Button>
                )}
                {!showForm && (
                  <>
                    <HeadingLight>Select your payment method</HeadingLight>
                    <ExistingCard>
                      <CardIcon
                        src={Images.digitalMediaAssets.cardLogo}
                        alt="card logo"
                      />
                      {/* {`**** ${card.last4} Exp ${card.expMonth}/${card.expYear}`} */}
                      {`***** ${card.expYear} Exp ${card.expMonth}`}
                    </ExistingCard>
                  </>
                )}
                {!cardLoading && card?.last4 && user && (
                  <Button
                    onClick={buyUsingSavedCard}
                    disabled={processing || savedCardProcessing}
                    width="50%"
                  >
                    {/* {savedCardProcessing || registerUserLoading
                      ? "Processing"
                      : `Use Saved Card ${card.last4}(${card.expMonth}/${card.expYear})`} */}
                    Use This Card
                  </Button>
                )}
                {!showForm && (
                  <Button
                    width="50%"
                    bordered
                    onClick={() => setShowForm(true)}
                    noTransform
                  >
                    Enter a New Card
                  </Button>
                )}
              </PaymentOption>

              <Wrapper>
                {showForm && (
                  <form noValidate onSubmit={handleSubmit(buy)}>
                    <InformationMsg>
                      Thank you for selecting a{" "}
                      {CommonUtility.getDetailsFromJson(
                        details.description,
                        "class"
                      )}{" "}
                      PoP. <br />
                      You will be donating{" "}
                      {`${CommonUtility.currencyFormat(
                        details?.euroPrice / 100,
                        "EUR"
                      )} | ${CommonUtility.currencyFormat(
                        details?.gbpPrice / 100,
                        "GBP"
                      )} | ${CommonUtility.currencyFormat(
                        details?.price / 100
                      )}`}
                    </InformationMsg>

                    {!UserService.isAuthenticated() && (
                      <>
                        <HeadingContainer>
                          <h3>CREATE ACCOUNT</h3>{" "}
                          <p>Needed to access your PoPs.</p>
                        </HeadingContainer>

                        <InputWrapper>
                          <Input
                            type="text"
                            placeholder="Email Address"
                            name="email"
                            {...register("email")}
                            errors={registerError?.email}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Input
                            type="text"
                            placeholder="Confirm Email Address"
                            name="confirmEmail"
                            {...register("confirmEmail")}
                            errors={registerError?.confirmEmail}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Input
                            type="password"
                            placeholder="Password"
                            name="password"
                            {...register("password")}
                            errors={registerError?.password}
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <Input
                            type="password"
                            placeholder="Confirm password"
                            name="confirmPassword"
                            {...register("confirmPassword")}
                            errors={registerError?.confirmPassword}
                            showPassword={showConfirmPassword}
                            setShowPassword={setShowConfirmPassword}
                          />
                        </InputWrapper>
                      </>
                    )}
                    <>
                      <HeadingContainer>
                        <h3>PAYMENT CARD DETAILS</h3>{" "}
                      </HeadingContainer>
                      <CardInput>
                        <CardNumberElement options={options} />
                      </CardInput>

                      <Row>
                        <Col md={7} lg={7} sm={12} xs={12}>
                          <CardInput>
                            <CardExpiryElement options={options} />
                          </CardInput>
                        </Col>
                        <Col md={5} lg={5} sm={12} xs={12}>
                          <CardInput>
                            <CardCvcElement options={options} />
                          </CardInput>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={7} lg={7} sm={12} xs={12}>
                          <InputWrapper>
                            <Input
                              {...frmPayment("name")}
                              errors={errors?.name}
                              placeholder="Card Holder Name"
                              name="name"
                            />
                          </InputWrapper>
                        </Col>
                        <Col md={5} lg={5} sm={12} xs={12}>
                          <InputWrapper>
                            <DropdownSelector
                              {...frmPayment("currency")}
                              errors={errors?.currency}
                              name="currency"
                              options={["EUR", "USD", "GBP"]}
                              defaultOption="EUR"
                              setValue={setValue}
                            />
                          </InputWrapper>
                        </Col>
                        <Col md={12} lg={12} sm={12} xs={12}>
                          <InputWrapper>
                            {" "}
                            <Input type="text" placeholder="City" />
                          </InputWrapper>
                        </Col>

                        <Col md={6} lg={6} sm={12} xs={12}>
                          <InputWrapper>
                            <Input type="text" placeholder="State/Province" />
                          </InputWrapper>
                        </Col>
                        <Col md={6} lg={6} sm={12} xs={12}>
                          <InputWrapper>
                            <Input type="text" placeholder="ZIP/Postal" />
                          </InputWrapper>
                        </Col>
                      </Row>
                    </>

                    <>
                      <div className="agreement-text-style">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="terms"
                            checked={termChecked}
                            onChange={(e) => setTermChecked(e.target.checked)}
                          />
                          <span className="default"></span>
                        </label>
                        By checking here and continuing, I agree to the{" "}
                        <a
                          rel="noopener noreferrer"
                          href="https://www.pitchinforukraine.org/terms-conditions/"
                          target="_blank"
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          rel="noopener noreferrer"
                          href=" https://www.pitchinforukraine.org/privacy-policy/"
                          target="_blank"
                        >
                          Privacy Policy.
                        </a>
                      </div>
                      <div className="agreement-text-style">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="saveCard"
                            {...frmPayment("saveCard")}
                          />
                          <span className="default"></span>
                        </label>
                        Save card for future payment
                      </div>
                      <div className="agreement-text-style">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="emailSubscribe"
                            checked={emailSubscribe}
                            onChange={(e) =>
                              setEmailSubscribe(e.target.checked)
                            }
                          />
                          <span className="default"></span>
                        </label>
                        By checking here, I agree to receive marketing emails.
                      </div>
                      <ButtonWrapper>
                        <Button
                          type="submit"
                          disabled={processing || savedCardProcessing}
                        >
                          {processing || registerUserLoading
                            ? "Processing"
                            : "Donate"}
                        </Button>
                      </ButtonWrapper>
                    </>
                  </form>
                )}
              </Wrapper>
            </Right>
          </Col>
        </Row>
      </Container>
      <MessagePopup
        showModal={!!error}
        toggle={() => setError("")}
        message={error}
      />

      {transactionId && (
        <OverlayLoadingBar title={"Preparing your PoP, please wait..."} />
      )}
      <div className="coinbase_container">
        <CoinbaseCommerceButton
          styled={false}
          chargeId={chargeCode}
          onChargeSuccess={() => onTransactionComplete()}
          onChargeFailure={(e) => coinBaseError(e.code)}
          onModalClosed={() => onCloseCoinBase()}
          className="coinbase-button"
        >
          Complete Crypto Transaction
        </CoinbaseCommerceButton>
      </div>
    </>
  );
};

export default Payment;
