import React, { useState } from "react";
import { Button } from "elements/Button";
import { Input } from "elements/Input";
import { Link } from "react-router-dom";
import Document from "layout/Document";
import * as yup from "yup";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserService } from "utility/services";
import { ErrorMessages } from "utility/constants/common";
import { Row, Col } from "reactstrap";
import { Heading, SubHeading, Text } from "elements/Common";
import { MessagePopup } from "components/MessagePopup";

const Form = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  @media (max-width: 668px) {
    padding: 0rem;
  }
`;
const Links=styled(Link)`
font-size: 1.563rem !important;
`;
const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Right = styled.div`
  width: 100%;
  padding: 4rem 9rem 1rem 4rem;
  min-height: 93vh;
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    min-height: 65vh;
    height: auto;
  }
  & h2 {
    text-align: left;
    font-size: 2rem;
    color: ${({ theme }) => theme.heading};
    text-align: left;
    @media (max-width: 1023px) {
      font-size: 1.8rem;
    }
    @media (max-width: 668px) {
      font-size: 1.5rem;
    }
  }
  & p {
    margin: 0rem;
    padding: 0rem;

    & a {
      color: ${({ theme }) => theme.heading};
      font-weight: 100 !important;
    }
  }
  @media (max-width: 668px) {
    margin-bottom: 2rem;
  }
`;
const InputWrapper = styled.div`
  padding: 0.5rem 0rem;
`;
const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  min-height: 93vh;
  height: 100%;
  padding: 4rem 6rem 1rem 6rem;
  background-color: ${({ theme }) => theme.secondary};

  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    padding-top: 8rem;
    min-height: 65vh;
    height: auto;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const ConfirmationText = styled.p`
  color: ${({ theme }) => theme.heading};
  margin-top: 1rem !important;
`;
const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required("Email address is required."),
});

const ForgotPassowrd = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    register: forgot,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const onSubmit = async (data) => {
    try {
      setSuccess(false);
      setLoading(true);
      await UserService.forgotPassword({ email: data.email });
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setError(ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Document title="forgot password">
      <Container>
        <Row className="gx-0 w-100">
          <Col lg={6} md={6} sm={12} xs={12}>
            <Left>
              <Content>
                <SubHeading>Forgot Password</SubHeading>
                <Heading>
                  Complete the process to access your Pitch IN for Ukraine Account
                </Heading>
              </Content>
            </Left>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Form>
              <Wrapper>
                <Right>
                  <Text>
                    Create an account by making a donation{" "}
                      <a href="https://www.pitchinforukraine.org/teams/"> here</a>.
                  </Text>
                  {!success ? (
                    <form
                      name="formForgot"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <InputWrapper>
                        <Input
                          {...forgot("email")}
                          errors={errors?.email}
                          placeholder="Email Address"
                          name="email"
                        />
                      </InputWrapper>
                     
                      <Links to="/"> Back to login</Links>
                      <ButtonWrapper>
                        <Button type="submit" width="50%">
                          {loading ? "Loading..." : "Submit"}
                        </Button>
                      </ButtonWrapper>
                    </form>
                  ) : (
                    <ConfirmationText>
                      Email has been sent to your email address, please check
                      your inbox to reset your password.
                    </ConfirmationText>
                  )}
                </Right>
              </Wrapper>
            </Form>
          </Col>
        </Row>
        <MessagePopup
        showModal={!!error}
        toggle={() => setError("")}
        message={error}
      />
      </Container>
    </Document>
  );
};

export default ForgotPassowrd;
