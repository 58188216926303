import styled from "styled-components";
import React, { useState, forwardRef ,useEffect} from "react";

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { ErrorMsg } from "./Common";
const DropdownContainer = styled(Dropdown)`
  .dropdown-toggle {
    width: 100% !important;
    padding: 0.6rem 1rem !important;
    text-align: left !important ;
    background-color: transparent !important ;
    color: ${({ theme }) => theme.input.placeholder} !important;
    border: 1px solid
      ${({ isError, theme }) => (isError ? theme.danger : theme.input.border)} !important;
    border-radius: 0 !important ;
    box-shadow: none !important;
    font-family: Helvetica !important;
  color: ${({ theme }) => theme.input.placeholder};
  font-size:1.563rem;

  }
  .dropdown-toggle::after {
    float: right !important ;
    margin-top: 0.6rem;
    font-size: 2rem;
  }

  .dropdown-menu {
    width: 100%;
  }
`;

const DropdownSelector = forwardRef(
  ({ defaultOption, options, setValue, name, errors, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const toggle = () => setIsOpen(!isOpen);
    const handleSelect = (e) => {
      setValue(name, e.target.value, { shouldValidate: true });
      setSelectedOption(e.target.value);
    };
    useEffect(() => {
      setValue(name, defaultOption, { shouldValidate: true });
      setSelectedOption(defaultOption);
    }, [defaultOption])
    
    return (
      <>
        <DropdownContainer
          isOpen={isOpen}
          toggle={toggle}
          {...props}
          ref={ref}
          isError={errors?.message}
          name={name}
        >
          <DropdownToggle caret>{selectedOption}</DropdownToggle>
          <DropdownMenu>
            {options &&
              options.map((option) => (
                <>
                  <DropdownItem value={option} onClick={(e) => handleSelect(e)}>
                    {option}
                  </DropdownItem>
                  <DropdownItem divider />
                </>
              ))}
          </DropdownMenu>
        </DropdownContainer>
        {errors && <ErrorMsg>{errors?.message}</ErrorMsg>}
      </>
    );
  }
);

export default DropdownSelector;
