import React, { useState } from "react";
import Document from "layout/Document";
import styled from "styled-components";
import { Input, MobileFormField } from "elements/Input";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserService } from "utility/services";
import { ErrorMessages } from "utility/constants/common";
import { Button } from "elements/Button";
import { Row, Col } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { Heading, SubHeading } from "elements/Common";
import { AuthHook } from "context/auth";
import { MessagePopup } from "components/MessagePopup";

const Form = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  @media (max-width: 668px) {
    padding: 0rem;
  }
`;
const Links = styled(Link)`
  font-size: 1.563rem !important;
`;
const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Right = styled.div`
  width: 100%;
  padding: 8rem 9rem 1rem 4rem;
  min-height: 93vh;
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    min-height: 65vh;
    height: auto;
  }
  & p {
    margin: 0rem;
    padding: 0rem;

    & a {
      color: ${({ theme }) => theme.heading};
      font-weight: 100 !important;
    }
  }
  @media (max-width: 668px) {
    margin-bottom: 2rem;
  }
`;
const InputWrapper = styled.div`
  padding: 0.5rem 0rem;
`;
const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  min-height: 93vh;
  height: 100%;
  padding: 4rem 6rem 1rem 6rem;
  background-color: ${({ theme }) => theme.secondary};

  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    padding-top: 8rem;
    min-height: 65vh;
    height: auto;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GoToSignIn = styled.p`
  margin-top: 1rem;
  font-size: 1.563rem;

  & a {
    font-weight: 600;
  }
`;

const registerSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required.")
    .min(4, "Password should be between 4 and 100.")
    .max(100, "Password should be between 4 and 100."),
  email: yup
    .string()
    .required("Email address is required.")
    .email("Invalid Email Address"),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  mobileNumber: yup
    .string()
    .trim()
    .required("Mobile number is required.")
    .test("invalid", "Invalid mobile number.", (value) => {
      const valid = isValidPhoneNumber(value) && isPossiblePhoneNumber(value);
      return valid;
    }),
});

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(registerSchema),
  });
  const { login: loginContext } = AuthHook();

  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [termChecked, setTermChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailSubscribe, setEmailSubscribe] = useState(true);

  const afterLogin = (result) => {
    UserService.storeToken(result.id_token);
    setTimeout(async () => {
      const user = await UserService.get();
      UserService.storeUser(user);
      loginContext(user);
      navigate("/app");
    });
  };

  const onSubmit = async (data) => {
    setError("");
    try {
      if (!termChecked)
        return setError(
          "You must agree with the Terms of Use and Privacy Policy."
        );
      setLoading(true);
      const req = {
        ...data,
        emailSubscribe,
        login: "any",
      };
      const loginReq = {
        password: data?.password,
        username: data?.email,
      };

      await UserService.register(req);
      const result = await UserService.login(loginReq);
      afterLogin(result);
    } catch (err) {
      setError(err?.error?.title || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Document title="register">
      <Container>
        <Row className="gx-0 w-100">
          <Col lg={6} md={6} sm={12} xs={12}>
            <Left>
              <Content>
                <SubHeading>Register</SubHeading>
                <Heading>Register to your Pitch IN for Ukraine Account</Heading>
              </Content>
            </Left>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Form>
              <Wrapper>
                <Right>
                  <form
                    ame="formRegister"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <InputWrapper>
                      <Input
                        placeholder="First Name"
                        name="firstName"
                        {...register("firstName")}
                        errors={errors?.firstName}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        placeholder="Last Name"
                        name="lastName"
                        {...register("lastName")}
                        errors={errors?.lastName}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        placeholder="Email"
                        name="email"
                        {...register("email")}
                        errors={errors?.email}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        placeholder="Password"
                        type="password"
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        name="password"
                        {...register("password")}
                        errors={errors?.password}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <MobileFormField
                        errors={
                          errors?.mobileNumber?.type === "typeError"
                            ? null
                            : errors.mobileNumber
                        }
                        defaultCountry="US"
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        control={control}
                      />
                    </InputWrapper>
                    <div className="agreement-text-style">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="terms"
                          checked={termChecked}
                          onChange={(e) => setTermChecked(e.target.checked)}
                        />
                        <span className="default"></span>
                      </label>
                      By checking here and continuing, I agree to the{" "}
                      <a
                        rel="noopener noreferrer"
                        href="https://www.pitchinforukraine.org/terms-conditions/"
                        target="_blank"
                      >
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        rel="noopener noreferrer"
                        href=" https://www.pitchinforukraine.org/privacy-policy/"
                        target="_blank"
                      >
                        Privacy Policy.
                      </a>
                    </div>
                    <div className="agreement-text-style">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="emailSubscribe"
                          checked={emailSubscribe}
                          onChange={(e) => setEmailSubscribe(e.target.checked)}
                        />
                        <span className="default"></span>
                      </label>
                      By checking here, I agree to receive marketing emails.
                    </div>
                    <ButtonWrapper>
                      <Button type="submit" disabled={!!loading} width="50%">
                        {loading ? "Loading..." : "Register"}
                      </Button>
                    </ButtonWrapper>
                  </form>
                  <GoToSignIn>
                    Already have an account? <Links to="/">Sign in</Links>
                  </GoToSignIn>
                </Right>
              </Wrapper>
            </Form>
          </Col>
        </Row>
        <MessagePopup
          showModal={!!error}
          toggle={() => setError("")}
          message={error}
        />
      </Container>
    </Document>
  );
};

export default Register;
