

import CommonUtility from 'utility/common';
import { APIPath } from '../constants/api';
import { CRUDService } from './crud';
import { BaseService } from "./base";

class Marketplace extends CRUDService {

    constructor(params) {
        const url = `${APIPath.marketplace}${CommonUtility.objectToParams(params)}`;
        super(url)

    }
    getCount() {
        return BaseService.get(APIPath.marketplaceCount, this.isSecure);
      }
}

const MarketplaceService = new Marketplace();
Object.freeze(MarketplaceService);
export { MarketplaceService };