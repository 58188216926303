import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.className = "";
    window.scrollTo(0, 0);
    ReactGA.send("pageview");
  }, [pathname]);

  return null;
}
