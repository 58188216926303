import React from "react";
import { Col } from "reactstrap";
import { Images } from "img";
import styled from "styled-components";
const Wrapper = styled.div`
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: auto;
  z-index: 0;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 668px) {
    flex-direction: column;
  }
`;
const FootballContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  & img {
    width: 8rem;
    position: absolute;
    bottom: -65px;
    z-index: -1;
  }
`;
const Links = styled.a`
  cursor: pointer;
  font-size: 1.125rem;
  
`;
const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.primary};
  padding: 1.5rem 2rem;
  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 668px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const Column = styled(Col)`
  display: flex;
  margin-right: auto;
  @media (max-width: 1023px) {
    justify-content: flex-start;
  }
  @media (max-width: 668px) {
    justify-content: flex-start;
    margin: auto;
  }
`;
const ListWrapper = styled.ul`
  display: flex;
  margin: 0rem;
  padding: 0rem;
  @media (max-width: 1023px) {
    padding: 0rem;
    flex-direction: column;
    text-align: left;
  }
  @media (max-width: 668px) {
    padding: 0rem;
    flex-direction: column;
    text-align: left;
  }
`;
const List = styled.li`
  list-style-type: none;
  border-right: 3px solid ${({ theme }) => theme.white};
  padding: 0rem 1rem;
  &:first-child {
    padding-left: 0rem;
    @media (max-width: 1023px) {
      padding-left: 0rem;
    }
    @media (max-width: 668px) {
      padding-left: 0rem;
    }
  }
  &:last-child {
    border-right: none;
  }
  @media (max-width: 1023px) {
    border-right: none;
    padding: 0rem;
  }
  @media (max-width: 668px) {
    border-right: none;
    padding: 0rem;
  }
  & a {
    text-decoration: none;
    white-space: nowrap;
    margin: 0rem;
    color: ${({ theme }) => theme.white} !important;
    @media (max-width: 1023px) {
      padding: 0rem;
    }
    @media (max-width: 668px) {
      padding: 0rem;
    }
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <FootballContainer>
        <img src={Images.ball} alt="ball" />
      </FootballContainer>
      <Container>
        <Column lg={7} md={12} sm={12} xs={12}>
          <ListWrapper>
            <List>
              <Links
                href="https://www.pitchinforukraine.org/terms-conditions/"
                target="_blank"
              >
                Terms of Service
              </Links>
            </List>
            <List>
              <Links
                href="https://www.pitchinforukraine.org/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </Links>
            </List>
            {/* <List>
              <Links href="https://www.pitchinforukraine.org/contact/" target="_blank">Support</Links>
            </List>
            <List>
              <Links  target="_blank">Charitable Documents</Links>
            </List> */}
          </ListWrapper>
        </Column>
      </Container>
    </Wrapper>
  );
};

export default Footer;
