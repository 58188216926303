import { useEffect,useState } from "react"
import { CardsService,PackTemplatesService,PaymentService } from "utility/services";
import { ErrorMessages,PaymentType } from "utility/constants/common";

export const SavedCardHook = () => {

    const [data,setData] = useState({});
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');
    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await PaymentService.savedCard();
                setData(result);
            } catch (error) {
                setError(error.message || ErrorMessages.default);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    },[])

    return { data,loading,error };
}

export const DetailsHook = (id,type) => {

    const [data,setData] = useState({});
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {

        const fetchPackData = async () => {
            try {
                setLoading(true);
                const result = await PackTemplatesService.getById(id);
                setData(result);
            } catch (error) {
                setError(error.message || ErrorMessages.default);
            } finally {
                setLoading(false);
            }
        }

        const fetchCardData = async () => {
            try {
                setLoading(true);
                const result = await CardsService.getById(id);
                setData(result);
            } catch (error) {
                setError(error.message || ErrorMessages.default);
            } finally {
                setLoading(false);
            }
        }

        if (id) {
            if (type === PaymentType.pack) {
                fetchPackData();
            } else if (type === PaymentType.card) {
                fetchCardData();
            }
        }
    },[id,type])

    return { data,loading,error };
}