export const theme = {
  body: "#FFFFFF",
  primary: "#173D6A",
  secondary: "#FFD12E",
  tertiaryGreen: "#66E092",
  tertiaryBlue: "#1962A9",
  black: "#000000",
  placeholder: "#858D95",
  danger: "#dc3545",
  maroon:"#A91919",
  success: "#155724",
  waring:"#ffb700",
  white: "#FFFFFF",
  grey: "#363D45",
  button: {
    background: "#005BBB",
    color: "#FFFFFF",
  },
  input: {
    placeholder: "#858D95",
    border:"#363D45"
  },
  heading: "#005BBB",
  subHeading: "#4790CC",
};

// export const theme = {
//   body: "#CAE7DF",
//   primary: "#E12B38",
//   secondary: "#E42E6A",
//   tertiaryGreen: "#EE7879",
//   tertiaryBlue: "#F4ABAA",
//   black: "#000000",
//   placeholder: "#858D95",
//   danger: "#dc3545",
//   success: "#155724",
//   white: "#FFFFFF",
//   grey: "#363D45",
//   button: {
//     background: "#F4ABAA",
//     color: "#FFFFFF",
//   },
//   input: {
//     placeholder: "#858D95",
//   },
//   heading: "#005BBB",
// };
