import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import { Images } from "img";
import { Button } from "elements/Button";
import { AuthHook } from "context/auth";
import styled from "styled-components";
import { LinkButton } from "elements/LinkButton";

const Wrapper = styled.div`
  width: 100%;
  min-height: 4.05rem;
  background-color: ${({ theme }) => theme.secondary};
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 9;
  @media (max-width: 1142px) {
    display: none;
  }
`;
const LogoContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;
const Logo = styled.img`
  width: 9.39rem;
  position: absolute;
  top: -0.92rem;
  left: 2rem;
  cursor: pointer;
  @media (max-width: 1264px) {
    top: 0.08rem;
  }
  @media (max-width: 1142px) {
    top: -0.92rem !important;
  }
  @media (max-width: 900px) {
    width: 10rem;
    left: auto;
  }
`;
const Column = styled(Col)`
  display: flex;
`;
const LeftSection = styled.div`
  display: flex;
  /* margin-right: auto; */
  margin-left: 4.2rem;
  @media (max-width: 1264px) {
    margin-left: 5rem;
  }
`;
const MidSection = styled.div`
  display: flex;
  margin: auto;
`;

const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0rem;
  padding-left: 0rem;
`;
const List = styled.li`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const VerticalBar= styled.div`
// width: 0.15rem ;
// height:1.2rem ;
// background-color :${({ theme }) => theme.primary};

// `

const Links = styled.a`
  text-decoration: none;
  margin: 0rem;
  padding: ${({ padding }) => (padding ? padding : "0rem")};
  color: ${({ theme }) => theme.primary} !important;
  font-family: Helvetica-Neue-Bold, sans-serif !important ;
  font-size: 1.125rem;
  font-weight: 600;
  @media (max-width: 1050px) {
    font-size: 1rem;
  }
  span {
    border-right: 3px solid ${({ theme }) => theme.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1rem;
    padding: 0rem 0.625rem;
    font-family: Helvetica-Neue-Bold, sans-serif !important ;
    font-size: 1.125rem;
    font-weight: 600;
    @media (max-width: 1050px) {
      font-size: 1rem;
    }
  }
  /* &:last-child {
    border-right: none ;
  } */
`;
const RightSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0rem;
`;
const SocialLinks = styled.a`
  border: none;
  cursor: pointer;
  padding: 0rem 0.4rem;
  &:first-child {
    padding-left: 0.7rem;
  }
`;
const SocialLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: baseline;
  transform: translateX(3px);
`;
const SocialIcons = styled.img`
  width: 1.14rem;
  @media (max-width: 1023px) {
    width: 3.2rem;
  }
  @media (max-width: 668px) {
    width: 2rem;
  }

  &#twitter {
    transform: translateX(2px);
  }
  &#instagram {
    width: 1.135rem;
    @media (max-width: 1023px) {
      width: 3.2rem;
    }
    @media (max-width: 668px) {
      width: 2rem;
    }
  }
  &#facebook {
    width: 0.58rem;
    @media (max-width: 1023px) {
      width: 1.5rem;
    }
    @media (max-width: 668px) {
      width: 1rem;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }
`;
const ModifiedButton = styled(Button)`
  padding: 0.2rem 1rem;
  font-size: 0.875rem !important;
  min-width: 8rem;
  margin: 0rem 0.4rem !important;
  margin-right: ${({ isRight }) => isRight && "0.96rem"} !important;
  text-transform: uppercase;
  font-family: Helvetica-Neue-Medium, sans-serif !important ;
  background-color: ${({ bordered, theme }) =>
    bordered ? "transparent" : `${theme.primary}`};
  border: ${({ theme }) => `2px solid ${theme.primary}`};
  color: ${({ bordered, theme }) => (bordered ? theme.primary : theme.white)};

  @media (max-width: 668px) {
    width: 40% !important;
  }
`;
const ModifiedMobileLinkButton = styled(LinkButton)`
  background-color: ${({ bordered, theme }) =>
    bordered ? "transparent" : `${theme.maroon}`};
  border: ${({ theme }) => `2px solid ${theme.maroon}`};
`;
const ModifiedLinkButton = styled(LinkButton)`
  padding: 0.2rem 1rem;
  font-size: 0.875rem !important;
  min-width: 8rem;
  margin: 0rem 0.4rem !important;
  text-transform: uppercase;
  font-family: Helvetica-Neue-Medium, sans-serif !important ;
  background-color: ${({ bordered, theme }) =>
    bordered ? "transparent" : `${theme.maroon}`};
  border: ${({ theme }) => `2px solid ${theme.maroon}`};
  color: ${({ bordered, theme }) =>
    bordered ? theme.primary : theme.white} !important;

  @media (max-width: 668px) {
    width: 40% !important;
  }
`;
const WrapperMobile = styled.div`
  @media (min-width: 1142px) {
    display: none;
  }
  z-index: 2;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.6rem;
  position: relative;
  height: 4.5rem;
  background-color: ${({ theme }) => theme.secondary};
`;
const MobileLogoContainer = styled(LogoContainer)`
  width: 100%;
  a {
    display: contents;
    text-decoration: none;
  }
`;
const MobileLogo = styled(Logo)`
  z-index: 2;
  width: 10rem;
`;
const NavigationContainer = styled.div`
  display: flex;
`;
const Hamburger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.4rem;
  position: absolute;
  top: 0.6rem;
  right: 1rem;
  z-index: 3;
  & button {
    border: none;
    background-color: transparent !important;
  }
  & span {
    font-size: 2rem;
  }
  & img {
    width: 2rem;
  }
`;
const NavLinksContainer = styled.div`
  min-height: 100vh;
  height: auto;
  width: 100%;
  padding: 7rem 2rem;
  padding-top: 9rem;
  background-color: ${({ theme }) => theme.secondary};
  z-index: 1;
  position: absolute;
  box-shadow: 0px 10px 8px 1px rgba(56, 86, 191, 0.77);
  -webkit-box-shadow: 0px 10px 8px 1px rgba(56, 86, 191, 0.77);
  -moz-box-shadow: 0px 10px 8px 1px rgba(56, 86, 191, 0.77);
`;

const ListWrapperMobile = styled.ul`
  padding-left: 0rem;
`;

const ListItem = styled.li`
  list-style-type: none;
  margin: 2rem 0rem;
`;

const MobileLinks = styled(Links)`
  font-family: Helvetica-Neue-Bold, sans-serif !important ;
  font-size: 1.125rem;
  font-weight: 600;
`;
const SocialWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;
const SocialListWrapper = styled.ul`
  display: flex;
  margin: 0rem;
  padding-left: 0rem;
`;
const Navbar = () => {
  const [dropNav, setDropNav] = useState(false);
  const restrictedRoutes = [];
  const handleHamburger = () => {
    setDropNav(!dropNav);
  };
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const isRestricted = () => {
    return restrictedRoutes.includes(pathname);
  };
  const { isAuthenticated, logout } = AuthHook();
  const socialLinks = useMemo(
    () => [
      {
        icon: Images.header.facebook,
        link: "https://www.facebook.com/PitchInForUkraine",
        alt: "facebook",
      },
      {
        icon: Images.header.twitter,
        link: "https://twitter.com/PitchIn4Ukraine",
        alt: "twitter",
      },
      {
        icon: Images.header.instagram,
        link: "https://www.instagram.com/PitchInForUkraine/",
        alt: "instagram",
      },
    ],
    []
  );
  return (
    <>
      <Wrapper>
        <Row className="gx-0 pe-md-2">
          <Column md={1} lg={1} sm={12}>
            <LogoContainer>
              <a href="https://www.pitchinforukraine.org/">
                <Logo src={Images.logo.main_logo} alt="logo" />
              </a>
            </LogoContainer>
          </Column>

          {!isRestricted() && (
            <>
              <Column md={7} lg={7} sm={12}>
                <LeftSection>
                  <ListWrapper>
                    <List>
                      <Links
                        href="https://www.pitchinforukraine.org/team/"
                        target="_blank"
                      >
                        <span>TEAMS</span>
                      </Links>
                    </List>

                    <List>
                      <Links
                        href="https://www.pitchinforukraine.org/supporter-club/"
                        target="_blank"
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          SUPPORTERS CLUB
                        </span>
                      </Links>
                    </List>
                    <List>
                      <Links
                        href="https://www.pitchinforukraine.org/partners-page/"
                        target="_blank"
                      >
                        <span>PARTNERS</span>
                      </Links>
                    </List>
                    <List>
                      <Links
                        href="https://www.pitchinforukraine.org/news/"
                        target="_blank"
                      >
                        <span>NEWS</span>
                      </Links>
                    </List>

                    <List>
                      <Links
                        href="https://www.pitchinforukraine.org/faqs/"
                        target="_blank"
                      >
                        <span>FAQS</span>
                      </Links>
                    </List>

                    <List>
                      <Links
                        href="https://www.pitchinforukraine.org/contact/"
                        target="_blank"
                        padding="0rem 0.625rem"
                      >
                        CONTACT
                      </Links>
                    </List>
                  </ListWrapper>
                </LeftSection>
              </Column>
              <Column md={1} lg={1} sm={12}>
                <MidSection>
                  <SocialListWrapper>
                    <List>
                      <SocialLinkContainer>
                        {socialLinks &&
                          socialLinks?.map((socialLink, i) => (
                            <SocialLinks
                              href={socialLink?.link}
                              target="_blank"
                              key={i}
                            >
                              <SocialIcons
                                id={socialLink?.alt}
                                src={socialLink.icon}
                                alt={socialLink?.alt}
                              />
                            </SocialLinks>
                          ))}
                      </SocialLinkContainer>
                    </List>
                  </SocialListWrapper>
                </MidSection>
              </Column>
              <Column md={3} lg={3} sm={12}>
                <RightSection>
                  {isAuthenticated ? (
                    <ButtonWrapper>
                      <ModifiedButton isRight bordered rounded onClick={logout}>
                        log out
                      </ModifiedButton>
                      <ModifiedButton rounded onClick={() => navigate("/app")}>
                        my account
                      </ModifiedButton>
                    </ButtonWrapper>
                  ) : (
                    <>
                      <ButtonWrapper>
                        <ModifiedLinkButton
                          rounded
                          href="https://www.pitchinforukraine.org/teams/"
                        >
                          donate
                        </ModifiedLinkButton>
                      </ButtonWrapper>
                      <ButtonWrapper>
                        <ModifiedButton
                          bordered
                          rounded
                          onClick={() => navigate("/")}
                        >
                          log in
                        </ModifiedButton>
                      </ButtonWrapper>
                    </>
                  )}
                </RightSection>
              </Column>
            </>
          )}
        </Row>
      </Wrapper>

      <WrapperMobile>
        <Container>
          <MobileLogoContainer>
            <a href="https://www.pitchinforukraine.org/">
              <MobileLogo src={Images.logo.main_logo} alt="logo" />
            </a>
          </MobileLogoContainer>
          {!isRestricted() && (
            <NavigationContainer>
              <Hamburger>
                <button onClick={handleHamburger}>
                  {!dropNav ? (
                    <span>
                      <img src={Images.hamburger} alt="hamburger" />
                    </span>
                  ) : (
                    <span>&#10005;</span>
                  )}
                </button>
              </Hamburger>
            </NavigationContainer>
          )}
        </Container>
        {dropNav && (
          <NavLinksContainer>
            <ListWrapperMobile>
              <ListItem onClick={handleHamburger}>
                <MobileLinks
                  href="https://www.pitchinforukraine.org/teams/"
                  target="_blank"
                >
                  TEAMS
                </MobileLinks>
              </ListItem>
              <ListItem onClick={handleHamburger}>
                <MobileLinks
                  href="https://www.pitchinforukraine.org/supporter-club/"
                  target="_blank"
                >
                  SUPPORTERS CLUB
                </MobileLinks>
              </ListItem>
              <ListItem onClick={handleHamburger}>
                <MobileLinks
                  href="https://www.pitchinforukraine.org/partners-page/"
                  target="_blank"
                >
                  PARTNERS
                </MobileLinks>
              </ListItem>
              <ListItem onClick={handleHamburger}>
                <MobileLinks
                  href="https://www.pitchinforukraine.org/news/"
                  target="_blank"
                >
                  NEWS
                </MobileLinks>
              </ListItem>
              <ListItem onClick={handleHamburger}>
                <MobileLinks
                  href="https://www.pitchinforukraine.org/faqs/"
                  target="_blank"
                >
                  FAQS
                </MobileLinks>
              </ListItem>
              <ListItem onClick={handleHamburger}>
                <MobileLinks
                  href="https://www.pitchinforukraine.org/contact/"
                  target="_blank"
                >
                  CONTACT
                </MobileLinks>
              </ListItem>

              {isAuthenticated ? (
                <>
                  <ListItem onClick={handleHamburger}>
                    <Button onClick={() => navigate("/app")}>MY ACCOUNT</Button>
                  </ListItem>
                  <ListItem onClick={handleHamburger}>
                    <Button bordered onClick={logout}>
                      LOG OUT
                    </Button>
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem onClick={handleHamburger}>
                    <ModifiedMobileLinkButton href="https://www.pitchinforukraine.org/teams/">
                      DONATE
                    </ModifiedMobileLinkButton>
                  </ListItem>
                  <ListItem onClick={handleHamburger}>
                    <Button
                      bordered
                      className="my-3"
                      onClick={() => navigate("/")}
                    >
                      LOG IN
                    </Button>
                  </ListItem>
                </>
              )}
              <SocialWrapper>
                {socialLinks &&
                  socialLinks?.map((socialLink, i) => (
                    <SocialLinks
                      key={i}
                      href={socialLink?.link}
                      target="_blank"
                    >
                      <SocialIcons
                        id={socialLink?.alt}
                        src={socialLink.icon}
                        alt={socialLink?.alt}
                      />
                    </SocialLinks>
                  ))}
              </SocialWrapper>
            </ListWrapperMobile>
          </NavLinksContainer>
        )}
      </WrapperMobile>
    </>
  );
};

export default Navbar;
