import { useEffect, useState } from "react";
import { PackTemplatesService } from "utility/services";
import { ErrorMessages } from "utility/constants/common";

export const PackTemplatesHook = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      let result = await PackTemplatesService.get();
      result = result.filter((x) => (x?.quantity || 0) !== -19);
      let buyNow = result.filter((item)=>(item?.quantity > 0));
      let comingSoon = result.filter((item)=>(item?.quantity === -21));
      let soldOut= result.filter((item)=>(item?.quantity === 0 || item?.quantity <0 && item?.quantity !==-21));
      setData([...buyNow,...comingSoon,...soldOut]);

    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
};

export const PackTemplateDetailsHook = (id) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await PackTemplatesService.getById(id);

      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error };
};

export const PackTemplateTaxHook = (id) => {
  const [data, setData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await PackTemplatesService.tax(id);
      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error };
};
