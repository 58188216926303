import React from "react";
import { Button } from "../../elements/Button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PaymentType } from "utility/constants/common";
import CommonUtility from "utility/common";
import { CardFooter, Category, Frame, ID, Title } from "elements/Common";
import { useMemo } from "react";
const Container = styled.div`
  padding: 1rem;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  display: flex;
`;
const CardImage = styled.img`
  cursor: pointer;
  width: 100%;
  margin-bottom: 0.6rem;
`;

const CurrencyContainer = styled.p`
  margin-bottom: 0rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.heading};
  font-size: 1.563rem;
  font-family: Helvetica-Neue-Bold, sans-serif !important ;
`;
const Pack = ({ item }) => {
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/payment?id=${id}&type=${PaymentType.pack}`);
  };

  const classTheme = useMemo(
    () => CommonUtility.getClassTheme(CommonUtility.getDetailsFromJson(item?.description, "class")),
    [item]
  );
  return (
    <Container className="px-4">
      <Frame bg={classTheme?.color} className="mb-2">
        <CardImage
          src={item?.picture?.url}
          alt="PoP"
          className="img img-fluid"
        />
        <CardFooter>
          <div>
            <Title color={classTheme?.fontColor}>
              {CommonUtility.getDetailsFromJson(item?.description, "name")}
            </Title>
            <ID color={classTheme?.fontColor}>
              PoP {CommonUtility.getDetailsFromJson(item?.description, "id")}
            </ID>
          </div>
          <div>
            <Category color={classTheme?.fontColor}>
              {/* {CommonUtility.getDetailsFromJson(item?.description, "class")} */}
              {item?.name}
            </Category>
          </div>
        </CardFooter>
        {/* <Logo>
          <Image src={Images?.logo?.main_logo} />
        </Logo> */}
      </Frame>
      <CurrencyContainer>
        {CommonUtility.currencyFormat(item?.euroPrice / 100, "EUR")} |{" "}
        {CommonUtility.currencyFormat(item?.gbpPrice / 100, "GBP")} |{" "}
        {CommonUtility.currencyFormat(item?.price / 100)}
      </CurrencyContainer>
      {(item.quantity || 0) > 0 ? (
        <Button onClick={() => handleClick(item?.id)} className="mt-3">
          Donate
        </Button>
      ) : (item.quantity || 0) === -21 ? (
        <Button disabled bordered className="mt-3">
          Coming Soon
        </Button>
      ) : (
        <Button bordered disabled className="mt-3">
          Sold Out
        </Button>
      )}
    </Container>
  );
};

export default Pack;
