import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
     
  * {
    font-family:Helvetica-Neue-Medium,sans-serif !important;
}

p{
  font-family: Helvetica-Neue-Regular,sans-serif !important;
}

h1 {
    font-family: Acumin-Pro-Extra-Condensed,sans-serif !important;
}

  `;
