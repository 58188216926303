import { BaseService } from "./base";
import { APIPath } from "../constants/api";
import { CommonConstant } from "../constants/common";
import { BrowserUtility } from "../browser-utility";
import { CRUDService } from "./crud";
import CommonUtility from "utility/common";
class User extends CRUDService {
  constructor() {
    super(APIPath.account);
  }

  login = (reqData) => {
    return BaseService.post(APIPath.login, reqData, false);
  };

  register = (reqData) => {
    return BaseService.post(APIPath.register, reqData, true);
  };

  registerActivate = (key) => {
    return BaseService.get(`${APIPath.registerActivate}?key=${key}`);
  };

  forgotPassword = (reqData) => {
    return BaseService.post(APIPath.forgotPassword, reqData, false);
  };

  resetPassword = (reqData) => {
    return BaseService.post(APIPath.resetPassword, reqData, false);
  };

  storeUser = (user) => {
    BrowserUtility.saveObj(CommonConstant.user, user);
  };

  storeToken = (token) => {
    BrowserUtility.save(CommonConstant.token, token);
  };

  getUser = () => {
    return BrowserUtility.getObj(CommonConstant.user);
  };

  getToken = () => {
    return BrowserUtility.get(CommonConstant.token) || "";
  };

  logout = () => {
    BrowserUtility.remove(CommonConstant.user);
    BrowserUtility.remove(CommonConstant.token);
  };

  isAuthenticated = () => {
    const token = this.getToken();
    return !!token;
  };

  history(filter) {
    const params = CommonUtility.objectToParams(filter);
    return BaseService.get(`${APIPath.userHistory}?${params}`);
  }

  historyById(id) {
    return BaseService.get(`${APIPath.userHistory}/${id}`);
  }

  branding() {
    return BaseService.get(APIPath.branding, false);
  }

  storeTenant = (tenant) => {
    BrowserUtility.save(CommonConstant.tenant, tenant);
  };

  getTenant = () => {
    return BrowserUtility.get(CommonConstant.tenant) || "";
  };

  setupBank() {
    return BaseService.get(APIPath.bankSetup);
  }
  changePassword = (reqData) => {
    return BaseService.post(APIPath.changePassword, reqData);
  };
  // mailSubscription(email_address, status) {
  //   const mailchimp = require('@mailchimp/mailchimp_marketing');
  //   mailchimp.setConfig({
  //     apiKey: process.env.REACT_APP_MAIL_SUBSCRIPTION_KEY,
  //     server: process.env.REACT_APP_MAIL_SUBSCRIPTION_SERVER_PREFIX,
  //   });

  //   return mailchimp.lists.addListMember(
  //     process.env.REACT_APP_MAIL_SUBSCRIPTION_LIST_ID,
  //     {
  //       email_address,
  //       status,
  //     }
  //   );
  // }
}

const UserService = new User();
Object.freeze(UserService);
export { UserService };
