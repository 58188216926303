import React, { useEffect, useMemo, useState } from "react";
import { Button } from "elements/Button";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { DetailsHook } from "hooks/payment";
import { LoadingBar } from "components/LoadingBar";
import {
  CardFooter,
  Category,
  Frame,
  ID,
  Title,
  Heading,
  SubHeading,
} from "elements/Common";
import CommonUtility from "utility/common";
import { AuthHook } from "context/auth";
const Form = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  @media (max-width: 668px) {
    padding: 0rem;
  }
`;
const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Right = styled.div`
  width: 100%;
  padding: 4rem 6rem 1rem 4rem;
  min-height: 93vh;
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    min-height: 65vh;
    height: auto;
  }
  & p {
    text-align: left;
    font-size: 1.563rem;
    color: ${({ theme }) => theme.heading};
    text-align: left;
    @media (max-width: 1023px) {
      font-size: 1.8rem;
    }
    @media (max-width: 668px) {
      font-size: 1.5rem;
    }
    & span {
      color: ${({ theme }) => theme.heading};
      font-weight: bold;
    }
  }
  @media (max-width: 668px) {
    margin-bottom: 2rem;
  }
`;
const CardImage = styled.img`
  cursor: pointer;
  width: 100%;
  margin-bottom: 0.6rem;
`;

const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  min-height: 93vh;
  height: 100%;
  padding: 10rem 6rem 1rem 4rem;
  background-color: ${({ theme }) => theme.secondary};

  @media (max-width: 1023) {
    padding: 2rem;
  }
  @media (max-width: 669px) {
    padding: 2rem;
    padding-top: 12rem;
    min-height: 65vh;
    height: auto;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PaymentConfirmation = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const { user } = AuthHook();
  let { search } = useLocation();
  useEffect(() => {
    const id = new URLSearchParams(search).get("id");
    if (id) {
      setId(id);
    }
    const type = new URLSearchParams(search).get("type");
    if (type) {
      setType(type);
    }
  }, [search]);

  const { data, loading: detailsLoading } = DetailsHook(id, type);

  const classTheme = useMemo(
    () =>
      CommonUtility.getClassTheme(
        CommonUtility.getDetailsFromJson(data.description, "class")
      ),
    [data]
  );
  return (
    <>
      <Container>
        <Row className="gx-0 w-100">
          <Col lg={6} md={6} sm={12} xs={12}>
            <Left>
              <Content>
                <SubHeading>Donation Complete</SubHeading>
                <Heading>
                  Thank you for your <br /> donation supporting the people of
                  Ukraine.
                </Heading>
                {detailsLoading ? (
                  <LoadingBar dark />
                ) : (
                  <div className="px-4">
                    <Frame bg={classTheme?.color} className="mb-4">
                      <CardImage
                        src={data?.picture?.url}
                        alt={data?.picture?.name}
                        className="img img-fluid"
                      />
                      <CardFooter>
                        <div>
                          <Title color={classTheme?.fontColor}>
                            {`${user?.firstName} ${user?.lastName}`}
                          </Title>
                          <ID color={classTheme?.fontColor}>
                            <b>PoP</b>{" "}
                            {CommonUtility.getDetailsFromJson(
                              data?.description,
                              "id"
                            )}
                          </ID>
                        </div>
                        <div>
                          <Category color={classTheme?.fontColor}>
                            {CommonUtility.getDetailsFromJson(
                              data?.description,
                              "class"
                            )}
                          </Category>
                        </div>
                      </CardFooter>
                      {/* <Logo>
                      <Image src={Images?.logo?.main_logo} />
                    </Logo> */}
                    </Frame>
                  </div>
                )}
              </Content>
            </Left>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Form>
              <Wrapper>
                <Right>
                  <p>
                  The majority of PoPs will be available instantly in your <span>Pitch In For Ukraine</span> account. However, during high volume donation periods PoPs may take up to <span>48 hours</span> to process. 
                  </p>
                  <ButtonWrapper>
                    <Button width="50%" onClick={() => navigate("/app")}>
                      View My Account
                    </Button>
                  </ButtonWrapper>
                </Right>
              </Wrapper>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentConfirmation;
