import { useEffect,useRef } from "react"
import React from 'react'
import { IoIosArrowRoundBack } from 'react-icons/io';
import styled from "styled-components";

const Container = styled.div`
    position : relative;
    height: 100vh;
    width: 100%;  
`

const BackContainer = styled.div`
    position: absolute;
    top: 1em;
    left: 1em;
    color: white;
    z-index: 10;
    display: flex;
    align-items: center;
    font-size: large;
    cursor : pointer;
    transition: all 0.2s ease;

    &:hover{
            margin-left : 5px;
        }

`

const PlayerContainer = styled.div`
    height: 100%;
    width: 100%;
`

export const VideoPlayer = ({ otp,playbackInfo,backClick }) => {

    const container = useRef();

    useEffect(() => {

        const loadPlayer = () => {
            window.playerContainer = container.current;
            const video = new window.VdoPlayer({
                otp,
                playbackInfo,
                theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
                container: container.current
            });

            video.addEventListener(`load`,() => {
                video.play(); // this will auto-start the video
            });
        }

        if (window.VdoPlayer) {
            return loadPlayer();
        }
        const playerScript = document.createElement("script");
        playerScript.src = "https://player.vdocipher.com/playerAssets/1.6.10/vdo.js";
        document.body.appendChild(playerScript);
        playerScript.addEventListener("load",() => {
            return loadPlayer();
        });
    },[])

    return (
        <Container>
            <BackContainer onClick={backClick}>
                <IoIosArrowRoundBack size="2em" /> Back
            </BackContainer>
            <PlayerContainer ref={container}></PlayerContainer>
        </Container>
    )
}