import { APIPath } from "../constants/api";
import { BaseService } from "./base";
import { CRUDService } from "./crud";
import CommonUtility from "utility/common";

class Packs extends CRUDService {
  constructor() {
    super(APIPath.packs);
  }

  my() {
    return BaseService.get(APIPath.myPacks);
  }

  hasFeatureCard() {
    return BaseService.get(APIPath.packHasFeature);
  }

  buy(id, reqData, params) {
    return BaseService.post(
      `${APIPath.buyPack}/${id}?${CommonUtility.objectToParams(params)}`,
      reqData
    );
  }

  open(id) {
    return BaseService.get(`${APIPath.openPack}/${id}`);
  }
}

const PacksService = new Packs();
Object.freeze(PacksService);
export { PacksService };
